import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Card from '../../components/calendar/card/card'
import { useContext } from 'react'
import DataContext from '../../context/DataContext'
import "./Weeks.css"

function Weeks() {
  const {sliceIntoWeek} = useContext(DataContext);
  let {month} = useContext(DataContext);
  const [selectedWeek, setSelectedWeek] = useState([])
  const {appointmentsList} = useContext(DataContext);
  const {context_dispatch, context_state} = useContext(DataContext);

  let weekArr = sliceIntoWeek(0, month);
  
  function handleClickedDay(day, date) {
    context_dispatch({type: "setActiveDay", payload: day})
    context_dispatch({type: "setActiveDate", payload: date})
    context_dispatch({type: "setPicto", payload: "day"})
  }

  return (
    appointmentsList != null && weekArr.length > 1 &&
    <div className='weeks-container'>
      {weekArr.map((day, index) => {
        if(day.type == "actualMonth") {
          return (
            <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to="/day" onClick={() => handleClickedDay(day.day, day.date)}>
            <Card 
            activePanel="week" 
            activeDay={day.day}
            activeDate={day.date}
            type={day.type}
            key={index}
            />
          </Link>
          )
        } else {
          return (
            <Card 
            activePanel="week" 
            activeDay={day.day}
            activeDate={day.date}
            type={day.type}
            key={index}
            />
          )
        }
      }
      )}
    </div>
  )
}

export default Weeks
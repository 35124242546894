import React, { useEffect, useReducer, useRef, useState } from 'react'
import {BsArrowDownShort} from "react-icons/bs";
import Card from '../../components/calendar/card/card'
import { useContext } from 'react'
import DataContext from '../../context/DataContext'
import { Link } from 'react-router-dom'
import "./Months.css"



function Months() {

  let {selectedYear} = useContext(DataContext);
  let {selectedMonth} = useContext(DataContext);
  let {month} = useContext(DataContext);
  const {fetchMonth} = useContext(DataContext);
  const {appointmentsList} = useContext(DataContext);
  const {collaboratorsList} = useContext(DataContext)
  const {context_dispatch} = useContext(DataContext);

  function handleClickedDay(day, date, type) {
      context_dispatch({type: "setActiveDay", payload: day})
      context_dispatch({type: "setActiveDate", payload: date})
      context_dispatch({type: "setPicto", payload: "day"})

  }
  return (
    <div className='months-outer-container'>
      <div className="months-inner-container">
        {month.map((day, index) => {
        if(day.type == "actualMonth") {
          return (
            <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to="/Day" onClick={() => handleClickedDay(day.day, day.date, day.type)}>
              <Card 
              activePanel="month" 
              activeDay={day.day} 
              activeDate={day.date}
              type={day.type}
              key={index}
              />
            </Link>
          )
        } else {
          return (
            <Card 
            activePanel="month" 
            activeDay={day.day} 
            activeDate={day.date}
            type={day.type}
            key={index}
            />
          )
        }
        })}
      </div>
    </div>
  )

}

export default Months
import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'
import DataContext from '../../context/DataContext'

const PrivateRoute = ({children}) => {
    if("token" in localStorage === false) {
        return <Navigate to="/login" />
    }
        return (
            <>
            {children}
            <Outlet />
            </>
        )
    

}

export default PrivateRoute
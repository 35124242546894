import React, { useContext, useReducer, useEffect, useRef } from 'react'
import DataContext from '../../context/DataContext'
import PersonIcon from '../Collaborators/PersonIcon'
import "./Stats.css"

function Stats() {
    const {collaboratorsList} = useContext(DataContext)
    const {appointmentsList} = useContext(DataContext)
    const {activeDate} = useContext(DataContext)
    const {selectedMonth} = useContext(DataContext)
    const {selectedYear} = useContext(DataContext)
    const {context_dispatch} = useContext(DataContext)
    const {month} = useContext(DataContext);
    const {pharmacyInfos} = useContext(DataContext)
    const {fetchMonth} = useContext(DataContext)
    const {getDays} = useContext(DataContext)
    const {getMonthStr} = useContext(DataContext)


    let allYear = [];

    if(pharmacyInfos != null){
      allYear = pharmacyInfos.years_list.flat();
    }
    
    let date = new Date()

    let options = {
        year: "numeric",
        month: "long",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
    }

    date.setDate(activeDate)
    date.setMonth(selectedMonth-1)
    date.setFullYear(selectedYear)

    const fr = Intl.DateTimeFormat("fr-FR", options).format(date);

    const reducer = (state, action) => {
        switch (action.type) {
            case "setSelectedFilter":
                return {...state, selectedFilter: action.payload}
            case "setSelectedSortingFilter":
                return {...state, selectedSortingFilter: action.payload}
            case "setActiveWeekIndex":
                return {...state, activeWeekIndex: action.payload}
            case "toggleDaySelector":
                return {...state, isActiveDaySelector: action.payload}
            case "toggleMonthSelector":
                return {...state, isActiveMonthSelector: action.payload}
            case "toggleYearSelector":
                return {...state, isActiveYearSelector: action.payload}
            default:
                break;
        }
    }
    const [state, dispatch] = useReducer(reducer, {selectedFilter: "day", selectedSortingFilter: ">", isActiveDaySelector: false, isActiveMonthSelector: false, isActiveYearSelector: false, activeWeekIndex: 0})

    const handleSelection = (type, value) => {
        dispatch({type: "setSelectedFilter", payload: type})

        let updateCollaboratorsList = [...collaboratorsList]

        if(type === "day") {
            updateCollaboratorsList.forEach(collaborator => {
                collaborator.appointmentsCounter = 0;
            })
            appointmentsList
            .filter(appointment => appointment.appointmentDate.startingDate.split("-")[2].slice(0, 2) == (value === undefined ? activeDate : value))
            .forEach((appointment, index) => {

                updateCollaboratorsList.forEach((collaborator, i) => {
                    if(appointment.collaborator_id === collaborator._id) {
                        collaborator.appointmentsCounter++
                    }
                })
            });
            // console.log(updateCollaboratorsList);
            context_dispatch({type: "setCollaboratorList", payload: updateCollaboratorsList})
        } else if(type === "week") {
            updateCollaboratorsList.forEach(collaborator => {
                collaborator.appointmentsCounter = 0;
            })
            let startingDate = value === 0 ? 1 : value * 7; // here value is equal to the index of one of the <p> tags in the week selection
            let endingDate = startingDate === 28 ? getDays(selectedYear, selectedMonth) : startingDate + 7;
            appointmentsList
            .filter(appointment => appointment.appointmentDate.startingDate.split("-")[2].slice(0, 2) >= startingDate && appointment.appointmentDate.startingDate.split("-")[2].slice(0, 2) <= endingDate)
            .forEach((appointment, index) => {

                updateCollaboratorsList.forEach((collaborator, i) => {
                    if(appointment.collaborator_id === collaborator._id) {
                        collaborator.appointmentsCounter++
                    }
                })
            });
            // console.log(updateCollaboratorsList);
            context_dispatch({type: "setCollaboratorList", payload: updateCollaboratorsList})


        }else if(type === "month") {
            // console.log(appointmentsList);
            // if(collaboratorsList == null) {
            //     fetchMonth()
            // }
            updateCollaboratorsList.forEach(collaborator => {
                collaborator.appointmentsCounter = 0;
            })
            value
            .forEach((appointment, index) => {
                
                updateCollaboratorsList.forEach((collaborator, i) => {
                    if(appointment.collaborator_id === collaborator._id) {
                        
                        collaborator.appointmentsCounter++
                    }
                })
            });
            // console.log(updateCollaboratorsList);
            context_dispatch({type: "setCollaboratorList", payload: updateCollaboratorsList})
        }
    }

    const handleDaySelection = (day, date, bool) => {
        
        context_dispatch({type: "setActiveDay", payload: day})
        context_dispatch({type: "setActiveDate", payload: date})
        handleSelection("day", date);
        dispatch({type: "toggleDaySelector", payload: bool})
    }

    async function handleMonth(month) {
        const result = await fetchMonth(selectedYear, month)

        context_dispatch({type: "setSelectedMonth", payload: month})
        context_dispatch({type: "setActiveMonth", payload: getMonthStr(month)})
        handleSelection("month", result);
        dispatch({type: "toggleMonthSelector", payload: false})
    }

    function handleClickedYear(index) {
        context_dispatch({type: "setSelectedYear", payload: allYear[index]})
        dispatch({type: "toggleYearSelector", payload: false})
    }

    const handleWeekSelection = (index) => {
        dispatch({type: "setActiveWeekIndex", payload: index})
        handleSelection("week", index);
    }
    const alreadyRunned = useRef(false);

    useEffect(() => {
        if(alreadyRunned.current === false) {
            alreadyRunned.current = true;
            handleSelection("month", appointmentsList)
        }

    })

  return (
    <div className="stats-container">
        <p style={{fontSize: "18px"}}>Statistiques</p>
        <div className="selection-container">
            <p onClick={() =>handleSelection("day")} style={{opacity: state.selectedFilter === "day" ? 1 : 0.5}}>Jours</p>
            <p onClick={() =>handleWeekSelection(0)} style={{opacity: state.selectedFilter === "week" ? 1 : 0.5}}>Semaine</p>
            <p onClick={() =>handleSelection("month", appointmentsList)} style={{opacity: state.selectedFilter === "month" ? 1 : 0.5}}>Mois</p>
        </div>
        { state.selectedFilter === "week" && 
            <div className="stats-week-selection">
                <p onClick={() => handleWeekSelection(0)} style={{opacity: state.activeWeekIndex === 0 ? 1 : 0.5}}>1 - 7</p>
                <p onClick={() => handleWeekSelection(1)} style={{opacity: state.activeWeekIndex === 1 ? 1 : 0.5}}>7 - 14</p>
                <p onClick={() => handleWeekSelection(2)} style={{opacity: state.activeWeekIndex === 2 ? 1 : 0.5}}>14 - 21</p>
                <p onClick={() => handleWeekSelection(3)} style={{opacity: state.activeWeekIndex === 3 ? 1 : 0.5}}>21 - 28</p>
                {getDays(selectedYear, selectedMonth) === 28 ? null : <p onClick={() => handleWeekSelection(4)} style={{opacity: state.activeWeekIndex === 4 ? 1 : 0.5}}>28 - {getDays(selectedYear, selectedMonth)}</p>}
            </div>
        }
        <div className="date-selectors">
        { state.selectedFilter === "day" && <p onClick={() => dispatch({type: "toggleDaySelector", payload: true})} >{activeDate}</p>} 
        <p onClick={() => dispatch({type: "toggleMonthSelector", payload: true})}>{fr.split(" ")[0]}</p>
        <p onClick={() => dispatch({type: "toggleYearSelector", payload: true})}>{fr.split(" ")[1]}</p>
        </div>
        {
        state.isActiveDaySelector &&
        <>
            <div className="black-filter" onClick={() => dispatch({type: "toggleDaySelector", payload: false})}></div>
            <div className="day-selector-outer">
                <div className="day-selector-inner">
                { month.map((day, index) => 
                <div className="stats-day-container" key={index} onClick={() => handleDaySelection(day.day, day.date, false)}>
                    <p>{day.date}</p>
                </div>
                )}
                </div>
            </div>
        </>
        }
        {
        state.isActiveMonthSelector &&
        <>
            <div className="black-filter" onClick={() => dispatch({type: "toggleMonthSelector", payload: false})}></div>
            <div className="month-selector-outer">
                <hr />
                <div className="month-selector-inner">
                <p onClick={() => handleMonth(1)}>Janvier</p>
                <p onClick={() => handleMonth(2)}>Février</p>
                <p onClick={() => handleMonth(3)}>Mars</p>
                <p onClick={() => handleMonth(4)}>Avril</p>
                <p onClick={() => handleMonth(5)}>Mai</p>
                <p onClick={() => handleMonth(6)}>Juin</p>
                <p onClick={() => handleMonth(7)}>Juillet</p>
                <p onClick={() => handleMonth(8)}>Août</p>
                <p onClick={() => handleMonth(9)}>Septembre</p>
                <p onClick={() => handleMonth(10)}>Octobre</p>
                <p onClick={() => handleMonth(11)}>Novembre</p>
                <p onClick={() => handleMonth(12)}>Décembre</p>
                </div>
                <hr />
            </div>
        </>
        }
        {
        state.isActiveYearSelector &&
        <>
            <div className="black-filter" onClick={() => dispatch({type: "toggleYearSelector", payload: false})}></div>
            <div className="year-selector-outer">
                <hr />
                <div className="year-selector-inner">
                { allYear.map((year, index) =>
                    <p onClick={() => handleClickedYear(index)}>{year}</p>
                )}
                </div>
                <hr />
            </div>
        </>
        }
        <div className="sorting-selection">
        <p>Classer par ordre&nbsp;</p>
        {state.selectedSortingFilter === "<" && <b onClick={() => dispatch({type: "setSelectedSortingFilter", payload: ">"})}>croissant</b>}
        {state.selectedSortingFilter === ">" && <b onClick={() => dispatch({type: "setSelectedSortingFilter", payload: "<"})}>décroissant</b>}
        </div>
        <div className="stats-collaborators-container">
            { state.selectedSortingFilter === ">" &&
            collaboratorsList
            .sort((collaborator_a, collaborator_b) => collaborator_b.appointmentsCounter - collaborator_a.appointmentsCounter)
            .map((collaborator, index) => 
                <div className="collaborator">
                    <>
                    <div className="stats-first-row">
                        <div className="stats-left-items">
                            <PersonIcon color={collaborator.color }/>
                            <p>{collaborator.firstName} {collaborator.lastName}</p>
                        </div>
                        <p>{collaborator.appointmentsCounter}</p>
                    </div>
                    <hr />
                    </>
                </div>
            )
            }

            { state.selectedSortingFilter === "<" &&
            collaboratorsList
            .sort((collaborator_a, collaborator_b) => collaborator_a.appointmentsCounter - collaborator_b.appointmentsCounter)
            .map((collaborator, index) => 
                <div className="collaborator">
                    <>
                    <div className="stats-first-row">
                        <div className="stats-left-items">
                            <PersonIcon color={collaborator.color }/>
                            <p>{collaborator.firstName} {collaborator.lastName}</p>
                        </div>
                        <p>{collaborator.appointmentsCounter}</p>
                    </div>
                    <hr />
                    </>
                </div>
            )
            }
        </div>
    </div>
    
  )

}

export default Stats
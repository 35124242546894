import axios from "axios";

let axiosApi = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" ? "/" : "http://localhost:3002/",
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

axiosApi.interceptors.request.use(
  config => {
    config.headers['Authorization'] = "Bearer " + localStorage.getItem("token");
    config.headers['securityToken'] = localStorage.getItem("securityToken");
    config.headers['pharmacy_id'] = localStorage.getItem("pharmacy_id");
    return config
  }
)


export default axiosApi;

import React, {useContext} from 'react'
import "./Search.css"
import Loader from "../../components/Loader/Loader.js"
import DataContext from '../../context/DataContext'
import { ImEnlarge2 } from "react-icons/im";
import SearchCard from './SearchCard';

function Search() {

  const {searchResults} = useContext(DataContext)
  const {activeUser} = useContext(DataContext)
  const {appointmentStep} = useContext(DataContext)
  const {context_dispatch} = useContext(DataContext)

  const handleClickedCard = (index) => {
    context_dispatch({type: "setActiveAppointment", payload: index})
    context_dispatch({type: "setAppointmentStep", payload: "show"})
  }

  return (
    <div className="search-container">
        { searchResults == null && <Loader /> }
        { searchResults != null  &&
          searchResults
          .sort((a, b) => Date.parse(a.appointmentDate.startingDate) - Date.parse(b.appointmentDate.startingDate))
          .map((result, index) =>
            <div className="search-result-card" key={index} onClick={appointmentStep == null ? () => handleClickedCard(index) : null} style={appointmentStep == null ? {backgroundColor: activeUser.color, cursor: "pointer"} : {backgroundColor: activeUser.color}}>
              <SearchCard rdv={result} index={index}/>
            </div>
          )
        }

    </div>
  )
}

export default Search
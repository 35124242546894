import React, { useContext, useState } from 'react'
import { FaTrash } from "react-icons/fa";
import * as mdIcons from "react-icons/md";
import DataContext from '../../context/DataContext';
import "./DeleteButton.css"

function DeleteButton(props) {

    const [deleteStep, setDeleteStep] = useState(0);
    const {context_dispatch} = useContext(DataContext)

    const handleCancelButton = () => {
        context_dispatch({type: "setDeleteButtonArmed", payload: false})
        setDeleteStep(0)
    }

    const handleDeletebutton = () => {
        if(deleteStep === 1) {
            if(props.handleDeleteCollaborator != undefined) {
                props.handleDeleteCollaborator();
                context_dispatch({type: "setDeleteButtonArmed", payload: false})
            }
            if(props.handleDeleteAppointment != undefined) {
                props.handleDeleteAppointment();
                context_dispatch({type: "setDeleteButtonArmed", payload: false})
            }
            
        }
        context_dispatch({type: "setDeleteButtonArmed", payload: true})
        setDeleteStep(1);
    }

  return (
    <div className="delete-button-container">
        <div className={!props.disabled ? "icon-container": "icon-container disabled"} >
            { deleteStep === 0 && <div className="circle-button"><FaTrash color='white' onClick={!props.disabled ? () => handleDeletebutton() : null}/></div>  }
            { deleteStep === 1 && <div className="circle-button"><mdIcons.MdClose color='white' onClick={!props.disabled ? () => handleCancelButton() : null}/></div> }
            { deleteStep === 1 && <div className="text-button"><p onClick={!props.disabled ? () => handleDeletebutton() : null}>Confirmer la suppression</p></div>  }
        </div>
    </div>
  )
}

export default DeleteButton
import React, { useContext, useEffect } from 'react'
import "./Register.css"
import axiosApi from '../../services/axios.config'
import { useReducer } from 'react';
import logo from "../../assets/img/svg/Logo-O&P-CMJN.svg"
import { FaEye } from "react-icons/fa";
import jwt_decode from 'jwt-decode';
import {useNavigate, redirect} from "react-router-dom"
import DataContext from '../../context/DataContext';

function Register() {

  const {context_dispatch} = useContext(DataContext)
  const {isLoggedIn} = useContext(DataContext)

  const navigate = useNavigate()
    const reducer = (state, action) => {
        switch (action.type) {
            case "setSecurityKey":
                return {...state, key: action.payload}
            case "setKeyStatus":
              return {...state, keyStatus: action.payload}
            case "setPharmacyName":
              return {...state, pharmacyName: action.payload}
            case "setLoginName":
              return {...state, loginName: action.payload}
            case "passwInput":
                return {...state, passw: action.payload}
            case "passwVerifInput":
              return {...state, passwVerif: action.payload}
            case "handleConnMessage":
              return {...state, connMessage: action.payload}
            case "showPassword":
              return {...state, showPassword: action.payload}
            default:
                break;
        }
    }
    const [state, dispatch] = useReducer(reducer, {key: "", connMessage: "",loginName: "", passw: "", passwVerif: "", pharmacyName: "",  showPassword: false, keyStatus: false})

    const handleSubmitKeyCheck = (e) => {
        e.preventDefault();
        async function keyCheck() {
            try {
              const response = await axiosApi.post("/keyCheck", {key: state.key});
              localStorage.setItem("securityToken", response.data.token);
              dispatch({type: "handleConnMessage", payload: response.data.message});
              dispatch({type: "setKeyStatus", payload: true});

              // navigate("/Login")
            } catch (error) {
              dispatch({type: "handleConnMessage", payload: "Validation échoué, veuillez réessayez"});
            }
          }
        keyCheck()
    }

    const handleSubmitRegisteration= (e) => {
      e.preventDefault();

      if(state.passw != state.passwVerif) {
        dispatch({type: "handleConnMessage", payload: "Les mots de passe ne coincide pas"});
        return;
      } else {
      async function register() {
          try {
            const response = await axiosApi.post("/signup", 
            {
              securityToken: localStorage.getItem("securityToken"),
              pharmacyName: state.pharmacyName,
              loginName: state.loginName,
              pass: state.passw,
            });
            dispatch({type: "handleConnMessage", payload: "Enregistrement réussi"});
            setTimeout(() => {
              navigate("/Login")
            }, "2000")
          } catch (error) {
            dispatch({type: "handleConnMessage", payload: "Validation échoué, veuillez réessayez"});
          }
        }
        register()
      }

  }


  return (
    <div className="register-container">
      <img src={logo} alt="Logo agenda optic&price" />
      <p>{state.connMessage}</p>

      {state.keyStatus === false &&
      <form onSubmit={handleSubmitKeyCheck}>
        <div className="input-container">
          <label htmlFor="">Clé de sécurité</label>
          <input type="password" value={state.key} placeholder="Clé de sécurité" onChange={(e) => dispatch({type: "setSecurityKey", payload: e.target.value})} /> 
        </div>

          <button>Valider</button>
      </form>
      }
      {state.keyStatus === true &&
      <form onSubmit={handleSubmitRegisteration}>
        <div className="input-container">
          <label htmlFor="">Nom de vôtre pharmacie</label>
          <input type="text" value={state.pharmacyName} placeholder="Nom de vôtre pharmacie" onChange={(e) => dispatch({type: "setPharmacyName", payload: e.target.value})} /> 
        </div>

        <div className="input-container">
          <label htmlFor="">Identifiant de connexion</label>
          <input type="text" value={state.loginName} placeholder="Identifiant de connexion" onChange={(e) => dispatch({type: "setLoginName", payload: e.target.value})} /> 
        </div>

        <div className="register-password-container">
        <label htmlFor="">Mot de passe</label>
        <div>
          <input type={state.showPassword ? "text" : "password"} value={state.passw} placeholder="Insérez votre mot de passe" onChange={(e) => dispatch({type: "passwInput", payload: e.target.value})}/>
          <FaEye size={"1.5rem"} className={state.showPassword ? "password-eye active" : "password-eye"} onClick={() => dispatch({type: "showPassword", payload: !state.showPassword})}/>
        </div>

        </div>

        <div className="register-password-container">
          <label htmlFor="">Confirmation du mot de passe</label>
          <div>
            <input type={state.showPassword ? "text" : "password"} value={state.passwVerif} placeholder="Confirmation du mot de passe" onChange={(e) => dispatch({type: "passwVerifInput", payload: e.target.value})}/>
            <FaEye size={"1.5rem"} className={state.showPassword ? "password-eye active" : "password-eye"} onClick={() => dispatch({type: "showPassword", payload: !state.showPassword})}/>
          </div>
        </div>

          <button>Valider</button>
      </form>
      }
    </div>
  )
}

export default Register
import React from 'react'
import { IoAddCircle } from "react-icons/io5";
import { ImEnlarge2 } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { useContext } from 'react'
import DataContext from '../../context/DataContext';
import ModifyAppointment from '../../components/calendar/card/ModifyAppointment/ModifyAppointment';

function SearchCard(props) {
    let options = {
        year: "numeric",
        month: "long",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
    }

    let date = new Date(props.rdv.appointmentDate.startingDate.slice(0, 4), props.rdv.appointmentDate.startingDate.slice(5, 7)-1, props.rdv.appointmentDate.startingDate.slice(8, 10));
    date.setHours(props.rdv.appointmentDate.startingDate.slice(11, 13))
    date.setMinutes(props.rdv.appointmentDate.startingDate.slice(14, 16))
    // console.log(Intl.DateTimeFormat("fr-FR", options).format(date).split(" ")[0]);
    // console.log(date.toISOString());
    // date.setUTCFullYear(selectedYear, selectedMonth-1, props.activeDate);



    const {context_dispatch} = useContext(DataContext)
    // const {appointmentsList} = useContext(DataContext);
    const {activeAppointment} = useContext(DataContext)
    const {appointmentStep} = useContext(DataContext)
    const {activeUser} = useContext(DataContext)
    const {collaboratorsList} = useContext(DataContext)
    // console.log(props.index);

    function handleClickedAppointment(index, str, collaborator_id) {
        // console.log(collaborator_id);
        if(str == "close") {
            context_dispatch({type: "setActiveAppointment", payload: null})
            context_dispatch({type: "setAppointmentStep", payload: null})
        } else if(str == "show") {
            context_dispatch({type: "setActiveAppointment", payload: index})
            context_dispatch({type: "setAppointmentStep", payload: "show"})
        } else if(str == "modify") {
            context_dispatch({type: "setActiveAppointment", payload: index})
            context_dispatch({type: "setAppointmentStep", payload: "modify"})

            context_dispatch({type: "setActiveUser", payload: collaboratorsList.filter(collaborator => collaborator._id === props.rdv.collaborator_id)[0]  })
        }
    }
  return (
    <div className="search-rdv-container">
        <div className="iso-date">
            <p>{date.toISOString().slice(8,10)} {Intl.DateTimeFormat("fr-FR", options).format(date).split(" ")[0]} {Intl.DateTimeFormat("fr-FR", options).format(date).split(" ")[1]}</p>
        </div>
            <div className={"rdv-infos"}>
                <p className='rdv-hour'>{props.rdv.appointmentDate.startingDate.slice(11, 16)}</p>
                    <div className="day-right-items">
                        <p className='rdv-name'>{props.rdv.lastName}</p>
                        <p className='rdv-name'>{props.rdv.firstName}</p>
                        {/* <ImEnlarge2 onClick={()=> handleClickedAppointment(props.index, "show")}/>  */}
                    </div>
            </div> 
            <div className="description">
                <p>{props.rdv.description}</p>
            </div>

            {activeAppointment != null && <div className="black-opacity-background"></div>}
            {activeAppointment == props.index && appointmentStep === "show" &&
                <div className="search-rdv-infos" style={{backgroundColor: activeUser.color}}> 
                    <div className="top-items">
                        <p className='rdv-hour'>{props.rdv.appointmentDate.startingDate.slice(11, 16)}</p>
                        <p className='rdv-name'>{props.rdv.firstName}</p>
                        <p className='rdv-name'>{props.rdv.lastName}</p>
                        <IoClose size={"2rem"} onClick={() => handleClickedAppointment(props.index, "close")}/> 
                    </div>
                    <p>{props.rdv.description}</p>
                    <p className='modify-appointment' onClick={() => handleClickedAppointment(props.index, "modify")}>Modifier le rendez-vous</p>
                </div> 
            }
            {activeAppointment == props.index && appointmentStep === "modify" &&
                <div className={props.index === activeAppointment ? "modify-appointment-card" : "rdv-infos"} style={{backgroundColor: activeUser.color}}>
                    <HiOutlineArrowSmLeft size={"2rem"} onClick={() => handleClickedAppointment(props.index, "show")}/>
                    <ModifyAppointment
                        appointment_id={props.rdv._id}
                        firstName={props.rdv.firstName} 
                        lastName={props.rdv.lastName}
                        description={props.rdv.description}
                        startingDate={props.rdv.appointmentDate.startingDate}
                        endingDate={props.rdv.appointmentDate.endingDate}
                        collaborator_id={props.rdv.collaborator_id}
                        searchPage={true}
                        index={props.index}
                    />
                </div> 
            }
    </div>
  )
}

export default SearchCard
import React from 'react'
import "./PersonIcon.css"
import { BsFillPersonFill } from "react-icons/bs";

function PersonIcon(props) {
  return (
    <div className="person-icon-container" style={{backgroundColor: props.color, width: props.width, height: props.height}}>
        <BsFillPersonFill size={props.iconSize}/>
    </div>
  )
}

export default PersonIcon
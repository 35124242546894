import React, { useContext, useState } from 'react'
import "./SearchBar.css"
import {GoSearch} from "react-icons/go"
import {MdClose} from "react-icons/md";
import {motion} from "framer-motion"
import DataContext from '../../context/DataContext';
import axiosApi from '../../services/axios.config';
import {useNavigate} from "react-router-dom"

function SearchBar() {
  const navigate = useNavigate();
  const [searchbarActive, setSearchbarActive] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const {context_dispatch} = useContext(DataContext)

  const searchbarVariant = {
    show: {
      width: "300px",
      height: "2rem",
    },
    hide: {
      width: "150px",
      height: "2rem",
    }
  }

  const handleSearch = async () => {
    try {
      const response = await axiosApi.get("/searchAppointments", {
        headers: {
          'value': searchValue
        }
      });
      context_dispatch({type: "setSearchResults", payload: response.data.appointments})
    } catch (error) {
      console.error(error);
    }
  }

  function handleSearchResult() {
    setSearchbarActive(false);
    setSearchValue("")
    navigate("/search")
    handleSearch();
  }

  function clearSearchBar() {
    setSearchbarActive(false)
    setSearchValue("")
  }
  return (
    <motion.div 
    className="searchbar-container"
    animate={searchbarActive ? "show" : "hide"}
    variants={searchbarVariant}
    onClick={!searchbarActive ? () => setSearchbarActive(true) : null}
    // style={searchbarActive ? {backgroundColor: "var(--the-black)", color: "FFFFF"} : {backgroundColor: "unset",color: "var(--the-black)"}}
    transition={{
        duration: 0.05,
    }}
    // whileHover={{
    //   backgroundColor: "var(--the-black)",
    //   color: "white"
    // }}
    >
        { searchbarActive === false &&<GoSearch size={"1rem"}/> }
        { searchbarActive === false && <p>Rechercher</p>}
        { searchbarActive === true && 
        <form onSubmit={() => handleSearchResult()}>
          <input type="text" autoFocus value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
        </form> 
        }
        { searchbarActive === true &&<MdClose size={"1.5rem"} onClick={() => clearSearchBar()}/>}
    </motion.div>
  )
}

export default SearchBar
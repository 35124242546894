import React, { useContext, useReducer, useRef } from 'react'
import "./TopNav.css"
import {Link, useLocation, useNavigate} from "react-router-dom"
import DataContext from '../../context/DataContext';
import SearchBar from '../SearchBar/SearchBar';
import axiosApi from '../../services/axios.config';

import { FiLogOut } from "react-icons/fi";

function TopNav() {
  const location = useLocation();
  const {activeDate} = useContext(DataContext)
  const {selectedMonth} = useContext(DataContext)
  const {selectedYear} = useContext(DataContext)
  const {context_dispatch} = useContext(DataContext)
  const {getMonthStr} = useContext(DataContext)
  const {fetchMonth} = useContext(DataContext)
  const {month} = useContext(DataContext)
  const {pharmacyInfos} = useContext(DataContext)

  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault()
    localStorage.removeItem("token")
    navigate("/login")
}

  let date = new Date(selectedYear, selectedMonth-1, activeDate)
  const today = new Date();

  let allYear = [];
  const actualYear = today.getFullYear();
  const alreadyRunned = useRef(false);

  if(pharmacyInfos != null){
    allYear = pharmacyInfos.years_list.flat();

    if(allYear[allYear.length-1] != actualYear) {
      if(alreadyRunned.current == false) {
        alreadyRunned.current = true;
        
        axiosApi.put("/addYear", {
          newYear: actualYear
        })
      }
      allYear.push(actualYear);
    } 
  }

  let options = {
      year: "numeric",
      month: "long",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
  }

  const fr = Intl.DateTimeFormat("fr-FR", options).format(date);

  const reducer = (state, action) => {
    switch(action.type) {
      case "toggleDaySelector":
        return {...state, activeDaySelector: action.payload}
      case "toggleWeekSelector":
        return {...state, activeWeekSelector: action.payload}
      case "toggleMonthSelector":
        return {...state, activeMonthSelector: action.payload}
      case "toggleYearSelector":
        return {...state, activeYearSelector: action.payload}
    }
  }

  const [state, dispatch] = useReducer(reducer, {activeDaySelector: false, activeWeekSelector: false, activeMonthSelector: false, activeYearSelector: false})

  const handleDaySelection = (day, date, bool) => {
        
    context_dispatch({type: "setActiveDay", payload: day})
    context_dispatch({type: "setActiveDate", payload: date})
    // handleSelection("day", date);
    dispatch({type: "toggleDaySelector", payload: bool})
  }

  async function handleMonth(month) {
      const result = await fetchMonth(selectedYear, month)

      context_dispatch({type: "setSelectedMonth", payload: month})
      dispatch({type: "toggleMonthSelector", payload: false})
  }

  function handleClickedYear(index) {
    const result = fetchMonth(allYear[index])

    context_dispatch({type: "setSelectedYear", payload: allYear[index]})
    dispatch({type: "toggleYearSelector", payload: false})
  }
  return (
    <div className="topnav-container">
      <div className="topnav-first-row">
        <SearchBar />
          <div className="topnav-right-navitems">
              <Link to="/Staff" className={location.pathname === "/Staff" ? "topnav-link active" : "topnav-link"}>Collaborateurs</Link>
              <Link to="/Stats" className={location.pathname === "/Stats" ? "topnav-link active" : "topnav-link"}>Statistiques</Link>
              <Link to="/Print" className={location.pathname === "/Print" ? "topnav-link active" : "topnav-link"}>Imprimer un planning</Link>
              <FiLogOut className='logout-button' onClick={handleLogout} />
          </div>
      </div>
      <hr />
      <div className="date-selection-outer-container">
        <div className="date-selection">
          <h2>{location.pathname === "/Day" ? "Jour" : location.pathname === "/Weeks" ? "Semaine" : "Mois"}</h2>
          {location.pathname === "/Day" && <p onClick={()=> dispatch({type: "toggleDaySelector", payload: true})}>{activeDate}</p>}
          {state.activeDaySelector &&
          <div className="topnav-dayselector">
            {month.map((day, index) => 
            <div className="stats-day-container" key={index} onClick={() => handleDaySelection(day.day, day.date, false)}>
                <p>{day.date}</p>
            </div>
            )}
          </div>
          }
          
          <p onClick={()=> dispatch({type: "toggleMonthSelector", payload: true})}>{fr.split(" ")[0]}</p>
          {state.activeMonthSelector &&
            <div className="month-selector-inner">
            <p onClick={() => handleMonth(1)}>Janvier</p>
            <p onClick={() => handleMonth(2)}>Février</p>
            <p onClick={() => handleMonth(3)}>Mars</p>
            <p onClick={() => handleMonth(4)}>Avril</p>
            <p onClick={() => handleMonth(5)}>Mai</p>
            <p onClick={() => handleMonth(6)}>Juin</p>
            <p onClick={() => handleMonth(7)}>Juillet</p>
            <p onClick={() => handleMonth(8)}>Août</p>
            <p onClick={() => handleMonth(9)}>Septembre</p>
            <p onClick={() => handleMonth(10)}>Octobre</p>
            <p onClick={() => handleMonth(11)}>Novembre</p>
            <p onClick={() => handleMonth(12)}>Décembre</p>
            </div>
          }
          <p onClick={()=> dispatch({type: "toggleYearSelector", payload: true})}>{fr.split(" ")[1]}</p>
          {state.activeYearSelector &&
            <div className="year-selector-outer">
              <div className="year-selector-inner">
              { allYear.map((year, index) =>
                  <p style={{color: "white", backgroundColor: "var(--the-black)"}} onClick={() => handleClickedYear(index)}>{year}</p>
              )}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default TopNav
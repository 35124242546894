import { Children, createContext, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosApi from "../services/axios.config"
import useWindowDimensions from "../functions/getViewportDimensions"

const DataContext = createContext({});

const getDays = (year, actualMonth) => {
  return new Date(year, actualMonth , 0).getDate();
};

const getWeeks = (numOfDays) => {
  return Math.ceil(numOfDays / 7);
};

const getMonthStr = (num) => {
  let date = new Date();
  date.setMonth(num-1);
  const options = { month: 'long'};
  return new Intl.DateTimeFormat('fr-FR', options).format(date)
}

const previousMonday = (year, month) => {
  let date = new Date(year+"-"+month+"-"+"01");

  // console.log(date);

  if(date.getDay() > 1) {
    let result = (date.getDay() + 6) % 7;
    // console.log(result);
    return result;
  } else if(date.getDay() == 0) {
    return 6;
  }
}

const tillSunday = (year, month) => {
  let date = new Date (year, month, 0);

  if(date.getDay() > 0) {
    return (7 - date.getDay());
} 
  return 0;
}

const sliceIntoWeek = (startingIndex, month) => {
  return month.slice(startingIndex, startingIndex+7);
}

export const DataProvider = ({children}) => {

    const reducer = (state, action) => {
        switch (action.type) {
          case "enterAppointmentsList":
            return {...state, appointmentsList: action.payload}
          case "enterPharmacyInfos":
            return {...state, pharmacyInfos: action.payload}
          case "updatePharmacyInfos":
            return {...state, pharmacyInfos: [...state.pharmacyInfos, action.payload]}
          case "setSearchResults": 
            return {...state, searchResults: action.payload}
          case "addAppointment":
              return {...state, appointmentsList: [...state.appointmentsList, action.payload]}
          case "setCollaboratorList":
            return {...state, collaboratorsList: action.payload}
          case "addCollaborator":
            return {...state, collaboratorsList: [...state.collaboratorsList, action.payload]}
          case "setSelectedYear":
            return {...state, selectedYear: action.payload} 
          case "setSelectedMonth":
            return {...state, selectedMonth: action.payload}
          case "setActiveMonth":
            return {...state, activeMonth: action.payload}
          case "setNumOfDays":
            return {...state, numOfDays: action.payload}
          case "setNumOfWeeks":
            return {...state, numOfWeeks: action.payload}
          case "setSelectedWeek":
            return {...state, selectedWeek: action.payload}
          case "setWeekStartingIndex":
            return {...state, startingIndex: action.payload}
          case "setActiveDay":
            return {...state, activeDay: action.payload}
          case "setActiveDate":
            return {...state, activeDate: action.payload}
          case "setPicto":
            return {...state, activeNavPicto: action.payload}
          case "setActiveAppointment":
            return {...state, activeAppointment: action.payload}
          case "setAppointmentStep":
            return {...state, appointmentStep: action.payload}
          case "setColorsList":
            return {...state, colorsList: action.payload}
          case "setActiveUser":
            return {...state, activeUser: action.payload}
          case "setActiveColor":
            return {...state, activeColor: action.payload}
          case "setActiveColorIndex":
            return {...state, activeColorIndex: action.payload}
          case "setActiveColorIndexBuffer":
            return {...state, activeColorIndexbuffer: action.payload}
          case "setDeleteButtonArmed":
            return {...state, deleteButtonArmed: action.payload}
          case "toggleModal":
            return {...state, isModalOpen: action.payload}
          case "setModalContent":
            return {...state, modalContent: action.payload}
          case "clearModalContent":
            return {...state, modalContent: ""}
          case "setModalType":
            return {...state, modalType: action.payload}
          case "clearModalType":
            return {...state, modalType: ""}
          case "activateLogin":
            return {...state, isLoggedIn: action.payload}
          default:
            break;
        }
      }
      const date = new Date();

      const [month, setMonth] = useState([]);
      const [state, dispatch] = useReducer(reducer, {
        appointmentsList: null,
        pharmacyInfos: null,
        searchResults: null,
        collaboratorsList: [],
        selectedYear: date.getFullYear(), 
        selectedMonth: (parseInt(date.getMonth())+1),
        activeMonth: null,
        numOfDays: null,
        numOfWeeks: null,
        startingIndex: 0, 
        selectedWeek: [], 
        activeDate: date.getDate(), 
        activeDay: date.getDay(), 
        activeNavPicto: "months", 
        activeAppointment: null, 
        appointmentStep: null,
        activeUser: null,
        activeColor:  null, 
        activeColorIndex: 0,
        activeColorIndexbuffer: 0,
        deleteButtonArmed: false,
        isLoggedIn: false,
        colorsList: [
          {hex: "#AAC8E0", inUse: false }, {hex: "#E5DF9E", inUse: false }, {hex: "#CB97BD", inUse: false }, {hex: "#F18D82", inUse: false }, {hex: "#A5E3D7", inUse: false }, {hex: "#EEAC83", inUse: false }, {hex: "#B999C2", inUse: false }, {hex: "#4CAB8D", inUse: false }, {hex: "#9CE288", inUse: false }, {hex: "#6BC2DF", inUse: false }, {hex: "#B27C64", inUse: false }, {hex: "#AD70C1", inUse: false }, {hex: "#5CB1C3", inUse: false }, {hex: "#F07B94", inUse: false }
        ]
      })

      const alreadyFetched = useRef(false);
      const alreadyFetchedCollaborators = useRef(false);
      const alreadyFetchedPharmacyInfos = useRef(false);

      useEffect(()=> {
        if(alreadyFetchedCollaborators.current == false) {
          alreadyFetchedCollaborators.current = true;

          const getCollaborators = async () => {
            try {
                let response = await axiosApi.get("/getCollaborators");

                const colorsListWithInUseValues = [...state.colorsList]

                colorsListWithInUseValues.forEach((color, index) => {
                  for(let i = 0; i < response.data.collaborators.length; i++) {
                    // console.log(response.data.collaborators[i]);
                    if(response.data.collaborators[i].color === color.hex) {
                      response.data.collaborators[i].color_id = index;
                      color.inUse = true;
                    }
                  }
        
                });

                response.data.collaborators.forEach(collaborator => {
                  collaborator.appointmentsCounter = 0;
                })

                dispatch({type: "setColorsList", payload: colorsListWithInUseValues})
                dispatch({type: "setCollaboratorList", payload: response.data.collaborators})
                dispatch({type: "setActiveUser", payload: response.data.collaborators[0]})
            } catch (error) {
                console.error(error)
            }
        }
        getCollaborators();
        }

    })

    const { height, width } = useWindowDimensions();

    useEffect(()=> {
      if(alreadyFetchedPharmacyInfos.current == false) {
        alreadyFetchedPharmacyInfos.current = true;
        
        const getPharmacy = async () => {
          try {
              const response = await axiosApi.get("/getPharmacy");
              dispatch({type: "enterPharmacyInfos", payload: response.data.pharmacy})
          } catch (error) {
              console.error(error)
          }
      }
      getPharmacy();
      }

  })

    const getAppointments = async () => {
      try {
        let response = await axiosApi.get("/getAppointments", {
          headers: {
            'selected_year': state.selectedYear,
            'selected_month': state.selectedMonth
          }
        });
        dispatch({type: "enterAppointmentsList", payload: response.data.appointments})
      } catch (error) {
        console.error(error);
      }
    }
      useEffect(() => {
        if(alreadyFetched.current === false) {
          alreadyFetched.current = true;
          getAppointments();
        }

      }, [])

      let alreadyRunned = useRef(false)
      
      useEffect(() => {
      if(alreadyRunned.current === false) {
        alreadyRunned.current = true;
    
        // Add previous months days of the week if month doesnt start on monday,ex: monday 25 to 0 of the month start
        for(let i = previousMonday(state.selectedYear, state.selectedMonth); i >= 0; i--) {
          if(i == previousMonday(state.selectedYear, state.selectedMonth)) {
            continue;
          }
          setMonth(current => [...current, {day: new Date(state.selectedYear, state.selectedMonth-1,(-i)).getDay(), date: new Date(state.selectedYear, state.selectedMonth-1, (-i)).getDate(), type: "previousMonth"}])
        }
        // Add days of the month ex: 0 to 30
        for(let i = 1; i <= getDays(state.selectedYear, state.selectedMonth); i++) {
          setMonth(current => [...current, {day: new Date(state.selectedYear, (state.selectedMonth-1), i).getDay(), date: new Date(state.selectedYear, (state.selectedMonth-1), i).getDate(), type: "actualMonth"}])
        }
        // Add days until sunday if the months doesnt end on a sunday
        for(let i = 1; i <= tillSunday(state.selectedYear, state.selectedMonth); i++) {
          setMonth(current => [...current, {day: new Date(state.selectedYear, state.selectedMonth, i).getDay(), date: new Date(state.selectedYear, state.selectedMonth, i).getDate(), type: "nextMonth"}])
        }
        dispatch({type: "setActiveMonth", payload: getMonthStr(state.selectedMonth)})
      }
      }, [])

      async function fetchMonth(selected_year, selected_month){

        if(selected_year == null && selected_month == null) {
            selected_year = state.selectedYear;
            selected_month = state.selectedMonth
        } else if(selected_year == null) {
          selected_year = state.selectedYear;
        } else if(selected_month == null) {
          selected_month = state.selectedMonth;
        }
        try {
          const response = await axiosApi.get("/getAppointments", {
            headers: {
              'selected_year': selected_year,
              'selected_month': selected_month
            }
          });

          response.data.appointments.forEach(item => {
            for(let i = 0; i < state.collaboratorsList.length; i++) {
              if(item.collaborator_id === state.collaboratorsList[i]._id) {
                item.color = state.collaboratorsList[i].color
              }
            }
        })
          dispatch({type: "enterAppointmentsList", payload: response.data.appointments})

          dispatch({type: "setNumOfDays", payload: getDays(selected_year, selected_month)})

          setMonth([]);
          // Add previous months days of the week if month doesnt start on monday,ex: monday 25 to 0 of the month start
          for(let i = previousMonday(selected_year, selected_month); i >= 0; i--) {
            if(i == previousMonday(selected_year, selected_month)) {
              continue;
            }
            setMonth(current => [...current, {day: new Date(selected_year, selected_month-1,(-i)).getDay(), date: new Date(selected_year, selected_month-1, (-i)).getDate(), type: "previousMonth"}])
          }
          // Add days of the month ex: 0 to 30
          for(let i = 1; i <= getDays(selected_year, selected_month); i++) {
            setMonth(current => [...current, {day: new Date(selected_year+"-"+selected_month+"-"+i).getDay(), date: new Date(selected_year+"-"+selected_month+"-"+i).getDate(), type: "actualMonth"}])
          }
          // Add days until sunday if the months doesnt end on a sunday
          for(let i = 1; i <= tillSunday(selected_year, selected_month); i++) {
            setMonth(current => [...current, {day: new Date(selected_year, selected_month, i).getDay(), date: new Date(selected_year, selected_month, i).getDate(), type: "nextMonth"}])
          }
          dispatch({type: "setNumOfWeeks", payload: getWeeks(state.numOfDays)})
          return response.data.appointments // returning the value to access it in functions (Stats.js)
        } catch (error) {
          console.error(error);
        }
      }

      const alreadyRunnedAppointmentsColors = useRef(false)
      useEffect(() => {
    
        if(alreadyRunnedAppointmentsColors.current === false && state.appointmentsList != null) {
          console.log("running");
          alreadyRunnedAppointmentsColors.current = true;
    
        let appointmentListWithColor = [...state.appointmentsList]
    
          appointmentListWithColor.forEach(item => {
            for(let i = 0; i < state.collaboratorsList.length; i++) {
              if(item.collaborator_id === state.collaboratorsList[i]._id) {
                item.color = state.collaboratorsList[i].color
              }
            }
        })
    
        dispatch({type: "enterAppointmentsList", payload: appointmentListWithColor})
        }
      })

    return (
        <DataContext.Provider value={{
            appointmentsList: state.appointmentsList,
            pharmacyInfos: state.pharmacyInfos,
            collaboratorsList: state.collaboratorsList,
            searchResults: state.searchResults,
            selectedYear: state.selectedYear,
            selectedMonth: state.selectedMonth,
            numOfDays: state.numOfDays,
            numOfWeeks: state.numOfWeeks,
            month: month,
            activeMonth: state.activeMonth,
            activeDay: state.activeDay,
            activeDate: state.activeDate,
            activeNavPicto: state.activeNavPicto,
            context_state: state,
            appointmentStep: state.appointmentStep,
            activeAppointment: state.activeAppointment,
            activeUser: state.activeUser,
            colorsList: state.colorsList,
            activeColor: state.activeColor,
            activeColorIndex: state.activeColorIndex,
            activeColorIndexbuffer: state.activeColorIndexbuffer,
            deleteButtonArmed: state.deleteButtonArmed,
            modalContent: state.modalContent,
            modalType: state.modalType,
            isLoggedIn: state.isLoggedIn,
            height: height,
            width: width,
            context_dispatch: dispatch,
            fetchMonth: fetchMonth,
            getDays: getDays,
            getMonthStr: getMonthStr,
            sliceIntoWeek: sliceIntoWeek
        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;
import './App.css';
import {Routes, Route, Navigate, useNavigate} from "react-router-dom"
import DataContext, { DataProvider } from './context/DataContext';
import useWindowDimensions from './functions/getViewportDimensions';
import Loader from "./components/Loader/Loader"

import Months from "./pages/Months/Months"
import Weeks from "./pages/Weeks/Weeks"
import Day from "./pages/Day/Day"

import Print from './pages/Print/Print';
import Search from './pages/Search/Search';
import Stats from './pages/Stats/Stats';

import Collaborators from './pages/Collaborators/Collaborators';

import Header from "./components/Header/Header"
import HeaderSecondSection from './components/Header/HeaderSecondSection';
import Login from './pages/Login/Login';
import Modal from './components/Modal/Modal';
import React, { useContext, useEffect, useState } from 'react';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import TopNav from './components/Header/TopNav';
import Register from './pages/Register/Register';


function App() {
  const { height, width } = useWindowDimensions();

  return (
     
    <div className="app-outer-container">
    {height > width &&
      <div className="app-inner-container">
                  <Routes>
                    <Route path='/*'
                    element={
                    <PrivateRoute>
                      <DataProvider>
                      <Header />
                      <HeaderSecondSection />
                        <Routes>
                          <Route path="/" element={<Navigate to="/Months" />} />
                          <Route path="/Months" element={<Months />} />
                          <Route path="/Weeks" element={<Weeks />} />
                          <Route path="/Day" element={<Day />} />
                          <Route path="/Print" element={<Print />} />
                          <Route path="/Search" element={<Search />} />
                          <Route path="/Staff" element={<Collaborators />} />
                          <Route path="/Stats" element={<Stats />} />
                        </Routes>
                      </DataProvider>
                    </PrivateRoute>
                    }>
                    </Route>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                  </Routes>

      </div> 
      }
      {height < width &&
      <div className="app-inner-container">
                  <Routes>
                    <Route path='/*'
                    element={<PrivateRoute>
                      <DataProvider>
                      <Header />
                      <div className="desktop-app-right-container">
                        <TopNav />
                        <Routes>
                          <Route path="/" element={<Navigate to="/Months" />} />
                          <Route path="/Months" element={<Months />} />
                          <Route path="/Weeks" element={<Weeks />} />
                          <Route path="/Day" element={<Day />} />
                          <Route path="/Print" element={<Print />} />
                          <Route path="/Search" element={<Search />} />
                          <Route path="/Staff" element={<Collaborators />} />
                          <Route path="/Stats" element={<Stats />} />
                        </Routes>
                      </div>
                      </DataProvider>
                    </PrivateRoute>}
                    >
                    </Route>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                  </Routes>   
      </div> 
      }
    </div>
  );
}

export default App;

import React, { useReducer, useState } from 'react'
import {motion} from "framer-motion"
import "./Card.css"
import { useContext } from 'react'
import DataContext from '../../../context/DataContext'
import { IoAddCircle } from "react-icons/io5";
import { ImEnlarge2 } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import ModifyAppointment from './ModifyAppointment/ModifyAppointment';
import CreateAppointment from './CreateAppointment/CreateAppointment';
import Modal from '../../Modal/Modal';
import { AnimatePresence } from 'framer-motion';

function Card(props) {
    const {appointmentsList} = useContext(DataContext);
    const {activeAppointment} = useContext(DataContext)
    const {appointmentStep} = useContext(DataContext)
    const {collaboratorsList} = useContext(DataContext)
    const {activeUser} = useContext(DataContext)
    const {colorsList} = useContext(DataContext)

    const {context_dispatch} = useContext(DataContext)

    const {selectedMonth} = useContext(DataContext)
    const {selectedYear} = useContext(DataContext)

    const {height, width} = useContext(DataContext)

    const reducer = (state, action) => {
        switch(action.type) {
            case "toggleModal":
                return {...state, isModalOpen: action.payload}
            case "setModalContent":
                return {...state, modalContent: action.payload}
            case "setModalType":
                return {...state, modalType: action.payload}
        }
    }

    const [state, dispatch] = useReducer(reducer, {isModalOpen: false, modalContent: "", modalType: null})

    const date = new Date();

    if(props.type == "previousMonth") {
        date.setUTCFullYear(selectedYear, selectedMonth-2, props.activeDate);
    } else if(props.type == "nextMonth") {
        date.setUTCFullYear(selectedYear, selectedMonth, props.activeDate);
    } else {
        date.setUTCFullYear(selectedYear, selectedMonth-1, props.activeDate);
    }


    let options = {
        year: "numeric",
        month: "long",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      }

    // date.setUTCFullYear(selectedYear, selectedMonth-1, props.activeDate)
    
    const frDate = (Intl.DateTimeFormat("fr-FR", options).format(date));
    const day = frDate.split(" ")[2]

    const yearDateDay = date.toISOString().slice(0, 10);

    function handleClickedAppointment(index, str, collaborator_id) {
        console.log(props.type);
        // index === null ? setActiveAppointment(null) : setActiveAppointment(index)
        if(str == "close") {
            context_dispatch({type: "setActiveAppointment", payload: null})
            context_dispatch({type: "setAppointmentStep", payload: null})
        } else if(str == "show") {
            context_dispatch({type: "setActiveAppointment", payload: index})
            context_dispatch({type: "setAppointmentStep", payload: "show"})
        } else if(str == "modify") {
            context_dispatch({type: "setActiveAppointment", payload: index})
            context_dispatch({type: "setAppointmentStep", payload: "modify"})
            // console.log(collaboratorsList.filter(collaborator => collaborator._id === collaborator_id)  );
            const activeCollaborator = collaboratorsList.filter(collaborator => collaborator._id === collaborator_id)[0];
            if(activeCollaborator != undefined) {
                context_dispatch({type: "setActiveUser", payload: activeCollaborator })
            }
        }
    }

    const handleAddButton = () => {
        if(collaboratorsList < 1) {
            dispatch({type: "setModalType", payload: "error"})
            dispatch({type: "setModalContent", payload: "Veuillez ajouter un collaborateur"})
            dispatch({type: "toggleModal", payload: true})
        } else {
            context_dispatch({type: "setAppointmentStep", payload: "create"})
        }
    }
  return (
    <div className="card-container" style={props.type == "previousMonth" ? {border: "2px solid var(--darker-beige)", backgroundColor: "unset", opacity: "30%"} : props.type == "nextMonth" ? {border: "2px solid var(--darker-beige)", backgroundColor: "unset", opacity: "30%"} : null}>
        <div className="card-header">
            <p className='date-number'>{props.activeDate}</p>
            <p className='rdv-day'>{day}</p>
        </div>
        <motion.div 
        className="card-rdv"
        >
            <AnimatePresence>
                { state.isModalOpen && <Modal modalType={state.modalType} modalContent={state.modalContent} dispatch={dispatch}/>}
            </AnimatePresence>
            { appointmentsList != null &&
            appointmentsList.filter(date => date.appointmentDate.startingDate.slice(0, 10) == yearDateDay)
            .sort((a, b) => Date.parse(a.appointmentDate.startingDate) - Date.parse(b.appointmentDate.startingDate))
            .map((rdv, index) => 
                <div className="rdv-container"
                key={index}
                style={props.activePanel === "month" ? {backgroundColor: rdv.color} : null}
                >   { props.activePanel === "month" && 

                    <div className="rdv-infos">
                        <p className='rdv-hour'>{new Date(rdv.appointmentDate.startingDate).toString().slice(15,21)}</p>
                        <p className='rdv-name'>{rdv.lastName}</p>
                    </div>}
                    {props.activePanel === "week" && height > width &&
                        <div className={"rdv-infos"}>
                        <p className='rdv-hour' style={{backgroundColor: rdv.color}}>{new Date(rdv.appointmentDate.startingDate).toString().slice(15,21)}</p>
                    </div> }
                    {props.activePanel === "week" && height < width &&
                        <div className="rdv-infos" style={{backgroundColor: rdv.color, color: "black", width: "100%", borderRadius: "5rem", display: "flex", justifyContent: "space-between", padding: "0 1rem", alignItems: "center"}}>
                        <p className='rdv-hour'>{new Date(rdv.appointmentDate.startingDate).toString().slice(15,21)}</p>
                        <p className='rdv-name'>{rdv.lastName}</p>
                    </div> }
                     {props.activePanel === "day" && activeAppointment == null && height > width &&
                        <div className={"rdv-infos"} style={{backgroundColor: rdv.color}}>
                                <p className='rdv-hour'>{new Date(rdv.appointmentDate.startingDate).toString().slice(15,21)}</p>
                                <div className="day-right-items">
                                    <p className='rdv-name'>{rdv.lastName}</p>
                                    <ImEnlarge2 className='rdv-enlarge' onClick={()=> handleClickedAppointment(index, "show")}/> 
                                </div>
                        </div> 
                        }
                        {props.activePanel === "day" && activeAppointment == null && height < width &&
                        <div className={"rdv-infos"} style={rdv.color != undefined ? {backgroundColor: rdv.color} : {backgroundColor: "darkgrey"}}>
                        <div className="rdv-infos-first-row">
                            <p className='rdv-hour'>{new Date(rdv.appointmentDate.startingDate).toString().slice(15,21)}</p>
                            <div className="day-right-items">
                                <p className='rdv-firstName'>{rdv.firstName}</p>
                                <p className='rdv-name'>{rdv.lastName}</p>
                                <ImEnlarge2 className='rdv-enlarge' onClick={()=> handleClickedAppointment(index, "show")}/> 
                            </div>
                        </div>
                        <div className="rdv-infos-second-row">
                            <p>{rdv.description}</p>
                        </div>
                        <p className='rdv-infos-modify-textbutton' onClick={() => handleClickedAppointment(index, "modify", rdv.collaborator_id)}>Modifier le rendez-vous</p>
                        </div> 
                    }
                    { activeAppointment != null && <div className="black-opacity-background"></div>}
                    {props.activePanel === "day" && activeAppointment != null && appointmentStep === "show" &&
                        <div className={index === activeAppointment ? "rdv-infos active" : "rdv-infos"} style={{backgroundColor: rdv.color}}>
                            <div className="top-items">
                                <p className='rdv-hour'>{new Date(rdv.appointmentDate.startingDate).toString().slice(15,21)}</p>
                                <p className='rdv-name'>{rdv.firstName}</p>
                                <p className='rdv-name'>{rdv.lastName}</p>
                                <IoClose className='buttons' style={{cursor: "pointer"}} size={"2rem"} onClick={() => handleClickedAppointment(index, "close")}/> 
                            </div>
                            <p>{rdv.description}</p>
                            {(date.getTime() > new Date().getTime()) && <p className='modify-appointment' onClick={() => handleClickedAppointment(index, "modify", rdv.collaborator_id)}>Modifier le rendez-vous</p>}
                        </div> 
                    }
                    {props.activePanel === "day" && activeAppointment != null && appointmentStep === "modify" && index === activeAppointment &&
                        <div className={index === activeAppointment ? "modify-appointment-card" : "rdv-infos"} style={rdv.color != undefined ? {backgroundColor: activeUser.color} : {backgroundColor: "#FAF9F6"}}>
                            <HiOutlineArrowSmLeft className='rdv-modify-appointment-leftArrow-icon' size={"2rem"} onClick={() => handleClickedAppointment(index, "show")}/>
                            <IoClose className='rdv-modify-appointment-close-icon' style={{cursor: "pointer"}} size={"2rem"} onClick={() => handleClickedAppointment(index, "close")}/>
                            <ModifyAppointment
                                appointment_id={rdv._id}
                                firstName={rdv.firstName}
                                lastName={rdv.lastName}
                                description={rdv.description}
                                startingDate={rdv.appointmentDate.startingDate}
                                endingDate={rdv.appointmentDate.endingDate}
                                collaborator_id={rdv.collaborator_id}
                                collaborator_name={rdv.collaborator_name}
                                iterator={rdv.iterator}
                            />
                        </div> 
                    }

                </div>
            )
                }
        { appointmentStep === "create" && <div className="black-opacity-background"></div> }
        {appointmentStep === "create" &&
            <div className="modify-appointment-card" style={{backgroundColor: activeUser.color}}>
                <IoClose className={"rdv-modify-appointment-close-icon"} size={"2rem"} onClick={() => handleClickedAppointment(null, "close")}/> 
                <CreateAppointment
                activeDate={date.toISOString()}
                />
            </div> 
        }
        { props.activePanel === "day" && <div className="rdv-infos empty" onClick={() => handleAddButton()}>
            <IoAddCircle  size={"4rem"}/>
            <p style={{position: "absolute", zIndex: 3, marginTop: "100%"}}>Ajouter un rendez-vous</p>
        </div> }
        </motion.div>
        { (props.activePanel === "day") && 
        <div className="add-btn-container">
            <IoAddCircle size={"4rem"} onClick={() => handleAddButton()}/>
        </div> }
    </div>
  )
}

export default Card
import React, { useContext, useReducer, useRef } from 'react'
import ReactToPrint from 'react-to-print'
import * as rIcons from "react-icons/ri";
import DataContext from '../../context/DataContext'
import "./Print.css"

import PrintCard from './PrintCard/PrintCard'

function Print() {

  return (
    <div className="printpage-container">
        <PrintCard type={"day"}/>
        <PrintCard type={"weeks"}/>
        <PrintCard type={"months"}/>
    </div>
  )
}

export default Print
import React, { useContext } from 'react'
import DataContext from '../../context/DataContext'
import "./Collaborator.card.css"
import PersonIcon from './PersonIcon'

function CollaboratorCard(props) {
    const {context_dispatch} = useContext(DataContext)
    // console.log(props.collaborator.color);

    const handleActiveUser = (collaborator) => {
      
        context_dispatch({type: "setActiveUser", payload: collaborator})
        props.dispatch({type: "setActiveCollaboratorArrayPositionIndex", payload: props.index})
        props.dispatch({type: "setActiveStep", payload: 2})
        props.dispatch({type: "setCollaboratorFirstName", payload: collaborator.firstName})
        props.dispatch({type: "setCollaboratorLastName", payload: collaborator.lastName})
        props.dispatch({type: "setJobChoice", payload: collaborator.job})
        const newColorObj = {hex: collaborator.color, inUse: false}
        context_dispatch({type: "setActiveColor", payload: newColorObj})
        context_dispatch({type: "setActiveColorIndex", payload: collaborator.color_id})
        context_dispatch({type: "setActiveColorIndexBuffer", payload: collaborator.color_id})
    }


  return (
    <div className="collaborator-card-container" onClick={() => handleActiveUser(props.collaborator)}>
        <PersonIcon color={props.collaborator.color} width={"7rem"} height={"7rem"} iconSize={"2rem"}/>
        <p>{props.collaborator.firstName} {props.collaborator.lastName}</p>
    </div>
  )
}

export default CollaboratorCard
import React, {useRef, useEffect} from 'react'
import * as heroIcons from "react-icons/hi";
import * as mdIcons from "react-icons/md";
import { useReducer } from 'react';
import { useContext } from 'react'
import DataContext from '../../context/DataContext'
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import Logo from "../../assets/img/svg/Logo-OP.svg"
import LogoRose from "../../assets/img/svg/Logo-OP-rose.svg"

import DayIcon from "../../assets/img/svg/icon-day.svg"
import WeeksIcon from "../../assets/img/svg/icon-week.svg"
import MonthsIcon from "../../assets/img/svg/icon-month.svg"

import "./Header.css"

function Header() {
    const navigate = useNavigate()

    const reducer = (state, action) => {
        switch (action.type) {
            case "toggleDropdown":
                return {...state, isMenuOpen: !state.isMenuOpen}
            default:
                break;
        }
    }
    const [state, dispatch] = useReducer(reducer, { isMenuOpen: false});

    const {activeNavPicto} = useContext(DataContext);
    const {context_state, context_dispatch} = useContext(DataContext);

    const handleLogout = (e) => {
        e.preventDefault()
        localStorage.removeItem("token")
        navigate("/login")
    }

    const staticNavVariant = {
        open: {
            borderRadius: "1rem 1rem 0rem 0rem",
            transition: {
                duration: 0.2,
            }
        },
        closed: {
            borderRadius: "1rem 1rem 1rem 1rem",
            transition: {
                duration: 0.2,
                delay: 0.3
            }
        }
    }

    const itemVariants = {
        open: {
            opacity: 1,
            display: "flex",
            transition: {
                duration: 0.2,
            }
        },
        closed: {
            opacity: 0,
            display: "none",
            transition: {
                duration: 0.2,
            }
        }
    }

  return (
    <div className="header-container" >
        <motion.nav 
        className='static-nav'
            animate={state.isMenuOpen ? "open" : "closed"}
            variants={staticNavVariant}
        >
            <div className="logo-container">
                { !state.isMenuOpen && <img src={Logo} alt="Logo Optic And Price" /> }
                { state.isMenuOpen && <img src={LogoRose} alt="Logo Optic And Price" /> }
            </div>
            <div className="desktop-layout-container">
                <hr className='menu-hr'/>
                <div 
                className="header-links"
                >
                    <Link to="/Day" onClick={() => context_dispatch({ type: "setPicto", payload: "day"})}><img className={ context_state.activeNavPicto === "day" ? "navPicto active" : "navPicto"} src={DayIcon} alt="Icône de navigation pour les jours" /></Link> 
                    <Link to="/Weeks" onClick={() => context_dispatch({ type: "setPicto", payload: "weeks"})}><img className={ context_state.activeNavPicto === "weeks" ? "navPicto active" : "navPicto"} src={WeeksIcon} alt="Icône de navigation pour les semaines" /></Link>
                    <Link to="/Months" onClick={() => context_dispatch({ type: "setPicto", payload: "months"})}><img className={ context_state.activeNavPicto === "months" ? "navPicto active" : "navPicto"} src={MonthsIcon} alt="Icône de navigation pour les mois" /></Link>
                </div>
                <hr className='menu-hr'/>
                <div className="burger-container">
                { state.isMenuOpen === false && <heroIcons.HiOutlineMenu size={"2.5rem"} color={"white"} onClick={() => dispatch({type: "toggleDropdown"})} /> }
                { state.isMenuOpen === true && <mdIcons.MdClose size={"2.5rem"} color={"white"} onClick={() => dispatch({type: "toggleDropdown"})} /> }
                </div>
            </div>
        </motion.nav>
            <motion.nav 
            className='dynamic-nav'
            variants={{
                open: {
                    height: "150px",
                  transition: {
                    type: "spring",
                    bounce: 0,
                    delay: 0.2,
                    duration: 0.7,
                    delayChildren: 0.4
                  }
                },
                closed: {
                    height: "0px",
                  transition: {
                    type: "spring",
                    bounce: 0,
                    duration: 0.3
                  }
                }
              }}
              initial="closed"
              animate={state.isMenuOpen ? "open" : "closed"}
              layout
            >
            { state.isMenuOpen &&
            <React.Fragment>
            <motion.hr 
            variants={itemVariants}
            />
            <motion.div className="dynamic-items-container" 
            variants={itemVariants}
            >
                <Link to="/Staff" onClick={() => dispatch({type: "toggleDropdown"})}>Collaborateurs</Link>
                <Link to="/Stats" onClick={() => dispatch({type: "toggleDropdown"})}>Statistiques</Link>
                <button onClick={handleLogout}>Déconnexion</button>
            </motion.div>
            </React.Fragment> 
            }
            </motion.nav>
    </div>
  )
}

export default Header
import React, { useContext, useReducer, useRef, useEffect } from 'react'
import ReactToPrint from "react-to-print"
import { ImEnlarge2 } from "react-icons/im";
import Logo from "../../../assets/img/svg/Logo-O&P-CMJN.svg"
import * as rIcons from "react-icons/ri";
import * as mdIcons from "react-icons/md";
import "./PrintCard.css"
import "./PrintableSheet.css"

import DayIcon from "../../../assets/img/svg/icon-day-black.svg"
import WeeksIcon from "../../../assets/img/svg/icon-week-black.svg"
import MonthsIcon from "../../../assets/img/svg/icon-month-black.svg"
import DataContext from '../../../context/DataContext';
import PersonIcon from '../../Collaborators/PersonIcon';

function PrintCard(props) {
    const {activeDate} = useContext(DataContext)
    const {selectedMonth} = useContext(DataContext)
    const {selectedYear} = useContext(DataContext)
    const {getDays} = useContext(DataContext)
    const {month} = useContext(DataContext)
    const {context_dispatch} = useContext(DataContext)
    const {fetchMonth} = useContext(DataContext)
    const {getMonthStr} = useContext(DataContext)
    const {pharmacyInfos} = useContext(DataContext)
    const {collaboratorsList} = useContext(DataContext)
    const {appointmentsList} = useContext(DataContext)
    const {height, width} = useContext(DataContext)

    const reducer = (state, action) => {
        switch (action.type) {
            case "toggleCard":
                return {...state, cardOpen: action.payload}
            case "setActiveWeekIndex":
                return {...state, activeWeekIndex: action.payload}
            case "toggleDaySelector":
                return {...state, isActiveDaySelector: action.payload}
            case "toggleMonthSelector":
                return {...state, isActiveMonthSelector: action.payload}
            case "toggleYearSelector":
                return {...state, isActiveYearSelector: action.payload}
            case "setSelectedPrint":
                return {...state, selectedPrint: action.payload}
            case "setActiveDayAppointmentsList":
                return {...state, activeDayAppointmentsList: action.payload}
            case "setActiveWeekAppointmentsList":
                return {...state, activeWeekAppointmentsList: action.payload}
            case "setWeekDays":
                return {...state, weekDays: action.payload}
            default:
                break;
        }
    }
    const [state, dispatch] = useReducer(reducer, {selectedPrint: "day", isActiveDaySelector: false, isActiveMonthSelector: false, isActiveYearSelector: false, activeWeekIndex: 0, cardOpen: false, activeDayAppointmentsList: [], activeWeekAppointmentsList: [], weekDays: month.filter(date => date.date <= 7)})
    const hours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    let date = new Date()

    let options = {
        year: "numeric",
        month: "long",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
    }

    date.setUTCFullYear(selectedYear, selectedMonth-1, activeDate)
    const yearDateDay = date.toISOString().slice(0, 10);
    // console.log(yearDateDay);

    const fr = Intl.DateTimeFormat("fr-FR", options).format(date);

    let allYear = [];

    if(pharmacyInfos != null){
      allYear = pharmacyInfos.years_list.flat();
    }

    const toPrint = useRef();
    const weekToPrint = useRef();
    const monthToPrint = useRef();
    const handleDaySelection = (input_day, input_date, bool) => {
        
        context_dispatch({type: "setActiveDay", payload: input_day})
        context_dispatch({type: "setActiveDate", payload: input_date})
        date.setUTCDate(input_date);

        const dayAppointmentsList = appointmentsList.filter(appointment => appointment.appointmentDate.startingDate.slice(0, 10) == date.toISOString().slice(0, 10));
        console.log(dayAppointmentsList);

        dispatch({type: "setActiveDayAppointmentsList", payload: dayAppointmentsList});
        // handleSelection("day", date);
        dispatch({type: "toggleDaySelector", payload: bool})
    }

    async function handleMonth(month) {
        const result = await fetchMonth(selectedYear, month)

        context_dispatch({type: "setSelectedMonth", payload: month})
        context_dispatch({type: "setActiveMonth", payload: getMonthStr(month)})
        date.setUTCMonth(month-1);

        const dayAppointmentsList = result.filter(appointment => appointment.appointmentDate.startingDate.slice(0, 10) == date.toISOString().slice(0, 10));

        dispatch({type: "setActiveDayAppointmentsList", payload: dayAppointmentsList});
        dispatch({type: "toggleMonthSelector", payload: false})
    }

    function handleClickedYear(index) {
        context_dispatch({type: "setSelectedYear", payload: allYear[index]})
        dispatch({type: "toggleYearSelector", payload: false})
        date.setUTCFullYear(allYear[index]);
    }

    const handleWeekSelection = (index) => {
        dispatch({type: "setActiveWeekIndex", payload: index})

        let startingDate = index === 0 ? 1 : index * 7; // here value is equal to the index of one of the <p> tags in the week selection
        let endingDate = startingDate === 28 ? getDays(selectedYear, selectedMonth) : startingDate + 7;

        const appointments = appointmentsList.filter(appointment => appointment.appointmentDate.startingDate.split("-")[2].slice(0, 2) >= startingDate && appointment.appointmentDate.startingDate.split("-")[2].slice(0, 2) <= endingDate)
        console.log(appointments);
        dispatch({type:"setActiveWeekAppointmentsList", payload: appointments})
        dispatch({type:"setWeekDays", payload: month.filter(date => date.date >= startingDate && date.date < endingDate)})
    }
    const alreadyRunned = useRef(false)
    useEffect(() => {
        if(alreadyRunned.current === false) {
            alreadyRunned.current = true;
            const dayAppointmentsList = appointmentsList.filter(appointment => appointment.appointmentDate.startingDate.slice(0, 10) == yearDateDay);

            dispatch({type: "setActiveDayAppointmentsList", payload: dayAppointmentsList});
            if(width > height) {
                dispatch({type: "toggleCard", payload: true})
            }
        }
    })

  return (
    <div className="print-card-container">
        { props.type === "day" && 
            <>
            <div className="printcard-top-row">
            <img src={DayIcon} alt="Icone calendrier jour" />
                <div className="printcard-right-items">
                    <p>Jour</p>
                    {!state.cardOpen && height > width && <ImEnlarge2 onClick={() =>dispatch({type: "toggleCard" , payload: true})}/>}
                    {state.cardOpen && height > width && <mdIcons.MdClose size={"1.5rem"} onClick={() =>dispatch({type: "toggleCard" , payload: false})}/>}
                </div>
            </div>
                {state.cardOpen &&
                <>
                    <div className="printcard-dynamic-container">
                        
                    <p onClick={() => dispatch({type: "toggleDaySelector", payload: true})} >{activeDate}</p>
                    <p onClick={() => dispatch({type: "toggleMonthSelector", payload: true})}>{fr.split(" ")[0]}</p>
                    <p onClick={() => dispatch({type: "toggleYearSelector", payload: true})}>{fr.split(" ")[1]}</p>
                        
                    </div>
                    <div className="printcard-print-logo-container">
                    <ReactToPrint
                        trigger={() => 
                            <button>
                                <rIcons.RiPrinterLine size={"20px"} color={"white"}/>
                                <p>imprimer</p>
                            </button>
                        }
                        content={() => toPrint.current}
                    />
                    </div>
                </>
                }
                {
                state.isActiveDaySelector &&
                <>
                    <div className="black-filter" onClick={() => dispatch({type: "toggleDaySelector", payload: false})}></div>
                    <div className="day-selector-outer">
                        <div className="day-selector-inner">
                        { month.map((day, index) => 
                        <div className="stats-day-container" key={index} onClick={() => handleDaySelection(day.day, day.date, false)}>
                            <p>{day.date}</p>
                        </div>
                        )}
                        </div>
                    </div>
                </>
                }
                        {
        state.isActiveMonthSelector &&
        <>
            <div className="black-filter" onClick={() => dispatch({type: "toggleMonthSelector", payload: false})}></div>
            <div className="month-selector-outer">
                <hr />
                <div className="month-selector-inner">
                <p onClick={() => handleMonth(1)}>Janvier</p>
                <p onClick={() => handleMonth(2)}>Février</p>
                <p onClick={() => handleMonth(3)}>Mars</p>
                <p onClick={() => handleMonth(4)}>Avril</p>
                <p onClick={() => handleMonth(5)}>Mai</p>
                <p onClick={() => handleMonth(6)}>Juin</p>
                <p onClick={() => handleMonth(7)}>Juillet</p>
                <p onClick={() => handleMonth(8)}>Août</p>
                <p onClick={() => handleMonth(9)}>Septembre</p>
                <p onClick={() => handleMonth(10)}>Octobre</p>
                <p onClick={() => handleMonth(11)}>Novembre</p>
                <p onClick={() => handleMonth(12)}>Décembre</p>
                </div>
                <hr />
            </div>
        </>
        }
        {
        state.isActiveYearSelector &&
        <>
            <div className="black-filter" onClick={() => dispatch({type: "toggleYearSelector", payload: false})}></div>
            <div className="year-selector-outer">
                <hr />
                <div className="year-selector-inner">
                { allYear.map((year, index) =>
                    <p onClick={() => handleClickedYear(index)}>{year}</p>
                )}
                </div>
                <hr />
            </div>
        </>
        }
            </>
        }
        { props.type === "weeks" && 
            <>
            <div className="printcard-top-row">
            <img src={WeeksIcon} alt="Icone calendrier semaines" />
                <div className="printcard-right-items">
                    <p>Semaines</p>
                    {!state.cardOpen &&  height > width && <ImEnlarge2 onClick={() =>dispatch({type: "toggleCard", payload: true})}/>}
                    {state.cardOpen &&  height > width && <mdIcons.MdClose size={"1.5rem"} onClick={() =>dispatch({type: "toggleCard", payload: false})}/>}
                </div>
            </div>
                {state.cardOpen &&
                <>
                    <div className="printcard-dynamic-container">
                    <p onClick={() => dispatch({type: "toggleMonthSelector", payload: true})}>{fr.split(" ")[0]}</p>
                    <p onClick={() => dispatch({type: "toggleYearSelector", payload: true})}>{fr.split(" ")[1]}</p>
                        
                    </div>
                    <div className="printcard-week-selection">
                        <p onClick={() =>handleWeekSelection(0)} style={{opacity: state.activeWeekIndex === 0 ? 1 : 0.5}}>1 - 7</p>
                        <p onClick={() =>handleWeekSelection(1)} style={{opacity: state.activeWeekIndex === 1 ? 1 : 0.5}}>7 - 14</p>
                        <p onClick={() =>handleWeekSelection(2)} style={{opacity: state.activeWeekIndex === 2 ? 1 : 0.5}}>14 - 21</p>
                        <p onClick={() =>handleWeekSelection(3)} style={{opacity: state.activeWeekIndex === 3 ? 1 : 0.5}}>21 - 28</p>
                        {getDays(selectedYear, selectedMonth) === 28 ? null : <p onClick={() =>handleWeekSelection(4)} style={{opacity: state.activeWeekIndex === 4 ? 1 : 0.5}}>28 - {getDays(selectedYear, selectedMonth)}</p>}
                    </div>
                    <div className="printcard-print-logo-container">
                    <ReactToPrint
                        trigger={() => 
                            <button>
                                <rIcons.RiPrinterLine size={"20px"} color={"white"}/>
                                <p>imprimer</p>
                            </button>
                        }
                        content={() => weekToPrint.current}
                    />
                    </div>
                </>
                }
                                        {
        state.isActiveMonthSelector &&
        <>
            <div className="black-filter" onClick={() => dispatch({type: "toggleMonthSelector", payload: false})}></div>
            <div className="month-selector-outer">
                <hr />
                <div className="month-selector-inner">
                <p onClick={() => handleMonth(1)}>Janvier</p>
                <p onClick={() => handleMonth(2)}>Février</p>
                <p onClick={() => handleMonth(3)}>Mars</p>
                <p onClick={() => handleMonth(4)}>Avril</p>
                <p onClick={() => handleMonth(5)}>Mai</p>
                <p onClick={() => handleMonth(6)}>Juin</p>
                <p onClick={() => handleMonth(7)}>Juillet</p>
                <p onClick={() => handleMonth(8)}>Août</p>
                <p onClick={() => handleMonth(9)}>Septembre</p>
                <p onClick={() => handleMonth(10)}>Octobre</p>
                <p onClick={() => handleMonth(11)}>Novembre</p>
                <p onClick={() => handleMonth(12)}>Décembre</p>
                </div>
                <hr />
            </div>
        </>
        }
        {
        state.isActiveYearSelector &&
        <>
            <div className="black-filter" onClick={() => dispatch({type: "toggleYearSelector", payload: false})}></div>
            <div className="year-selector-outer">
                <hr />
                <div className="year-selector-inner">
                { allYear.map((year, index) =>
                    <p onClick={() => handleClickedYear(index)}>{year}</p>
                )}
                </div>
                <hr />
            </div>
        </>
        }
            </>
        }
        { props.type === "months" && 
            <>
            <div className="printcard-top-row">
            <img src={MonthsIcon} alt="Icone calendrier mois" />
                <div className="printcard-right-items">
                    <p>Mois</p>
                    {!state.cardOpen &&  height > width && <ImEnlarge2 onClick={() =>dispatch({type: "toggleCard", payload: true})}/>}
                    {state.cardOpen &&  height > width && <mdIcons.MdClose size={"1.5rem"} onClick={() =>dispatch({type: "toggleCard", payload: false})}/>}
                </div>
            </div>
                {state.cardOpen &&
                <>
                    <div className="printcard-dynamic-container">
                        <p onClick={() => dispatch({type: "toggleMonthSelector", payload: true})}>{fr.split(" ")[0]}</p>
                        <p onClick={() => dispatch({type: "toggleYearSelector", payload: true})}>{fr.split(" ")[1]}</p>
                    </div>
                    <div className="printcard-print-logo-container">
                    <ReactToPrint
                        trigger={() => 
                            <button>
                                <rIcons.RiPrinterLine size={"20px"} color={"white"}/>
                                <p>imprimer</p>
                            </button>
                        }
                        content={() => toPrint.current}
                    />
                    </div>
                </>
                }
                                        {
        state.isActiveMonthSelector &&
        <>
            <div className="black-filter" onClick={() => dispatch({type: "toggleMonthSelector", payload: false})}></div>
            <div className="month-selector-outer">
                <hr />
                <div className="month-selector-inner">
                <p onClick={() => handleMonth(1)}>Janvier</p>
                <p onClick={() => handleMonth(2)}>Février</p>
                <p onClick={() => handleMonth(3)}>Mars</p>
                <p onClick={() => handleMonth(4)}>Avril</p>
                <p onClick={() => handleMonth(5)}>Mai</p>
                <p onClick={() => handleMonth(6)}>Juin</p>
                <p onClick={() => handleMonth(7)}>Juillet</p>
                <p onClick={() => handleMonth(8)}>Août</p>
                <p onClick={() => handleMonth(9)}>Septembre</p>
                <p onClick={() => handleMonth(10)}>Octobre</p>
                <p onClick={() => handleMonth(11)}>Novembre</p>
                <p onClick={() => handleMonth(12)}>Décembre</p>
                </div>
                <hr />
            </div>
        </>
        }
        {
        state.isActiveYearSelector &&
        <>
            <div className="black-filter" onClick={() => dispatch({type: "toggleYearSelector", payload: false})}></div>
            <div className="year-selector-outer">
                <hr />
                <div className="year-selector-inner">
                { allYear.map((year, index) =>
                    <p onClick={() => handleClickedYear(index)}>{year}</p>
                )}
                </div>
                <hr />
            </div>
        </>
        }
            </>
        }
        {/* content that will be printed */}
        <div className="print-outer-container" style={{display: "none"}}>

            <div className="print-container-inner" ref={toPrint}>
            <style type="text/css" media="print">{"\
                @page {\ size: landscape;\ }\
            "}</style>

                <div className="print-first-col">
                        <img src={Logo} alt="logo optic and price" className='printable-logo'/>
                        <p>Planning du : <b>{activeDate} {fr.split(" ")[0]} {fr.split(" ")[1]}</b> </p>
                        <div className="print-hours-container">
                            {hours.map((hour, index) => 
                                <div className="hour-container">
                                    <div className="print-horizontal-aligned-items">
                                        <p>{hour}h</p>
                                        {state.activeDayAppointmentsList
                                        .filter(appointment => new Date(appointment.appointmentDate.startingDate).toString().slice(16,18) == hour)
                                        .map((appointment,index) =>
                                        <div className="print-day-appointment-container" style={{backgroundColor: appointment.color}}>
                                            <p>{new Date(appointment.appointmentDate.startingDate).toString().slice(16, 21)}</p>
                                            <p>{appointment.firstName} {appointment.lastName}</p>
                                            <p>{appointment.description}</p>
                                        </div>
                                        )}
                                    </div>
                                     <hr />
                                </div>
                            )}
                        </div>
                </div>


                <div className="print-sec-col">
                    <h2>Vos collaborateurs:</h2>
                    <div className="print-collaborators-container">
                        
                        {collaboratorsList.map((collaborator, index) =>
                            <div className="print-collaborator-container">
                                <PersonIcon color={collaborator.color} width={"2rem"} height={"2rem"}/>
                                <p>{collaborator.firstName} {collaborator.lastName}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="print-container-inner" ref={weekToPrint}>
            <style type="text/css" media="print">{"\
                @page {\ size: landscape;\ }\
            "}</style>

                <div className="print-first-col">
                        <img src={Logo} alt="logo optic and price" className='printable-logo'/>
                        <p>Planning du : <b>{activeDate} {fr.split(" ")[0]} {fr.split(" ")[1]}</b> </p>
                        <div className="print-days-container">
                                {state.weekDays.map((day, index) =>
                                <>
                                    <div className="print-day-container">
                                    <p>{new Date(selectedYear, selectedMonth-1, day.date).toLocaleDateString("fr-FR", options).split(" ")[2]}</p>
                                    <p>{day.date}</p>
                                    <div className="print-week-appointments-container">
                                        {state.activeWeekAppointmentsList
                                        .filter(appointment => appointment.appointmentDate.startingDate.slice(8,10) == day.date)
                                        .map((appointment,index) =>
                                        <div className="print-week-appointment-container" style={{backgroundColor: appointment.color}}>
                                            <div className="first-row">
                                                <p>{new Date(appointment.appointmentDate.startingDate).toString().slice(16, 21)}</p>
                                                <p>{appointment.firstName} {appointment.lastName.slice(0, 1)}</p>
                                            </div>
                                            <p>{appointment.description}</p>
                                        </div>
                                        )}
                                    </div>
                                </div>
                                {index <= 5 && <hr />}
                                </>
                                )}
                        </div>
                </div>


                <div className="print-sec-col">
                    <h2>Vos collaborateurs:</h2>
                    <div className="print-collaborators-container">
                        
                        {collaboratorsList.map((collaborator, index) =>
                            <div className="print-collaborator-container">
                                <PersonIcon color={collaborator.color} width={"2rem"} height={"2rem"}/>
                                <p>{collaborator.firstName} {collaborator.lastName}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="print-container-inner" ref={monthToPrint}>
            <style type="text/css" media="print">{"\
                @page {\ size: landscape;\ }\
            "}</style>

                <div className="print-first-col">
                        <img src={Logo} alt="logo optic and price" className='printable-logo'/>
                        <p>Planning de : <b>{fr.split(" ")[0]}</b> </p>
                        <div className="print-days-container">
                                {month.map((day, index) =>
                                <>
                                    <div className="print-day-container">
                                    <p>{new Date(selectedYear, selectedMonth-1, day.date).toLocaleDateString("fr-FR", options).split(" ")[2]}</p>
                                    <p>{day.date}</p>
                                    <div className="print-week-appointments-container">
                                        {state.activeWeekAppointmentsList
                                        .filter(appointment => appointment.appointmentDate.startingDate.slice(8,10) == day.date)
                                        .map((appointment,index) =>
                                        <div className="print-week-appointment-container" style={{backgroundColor: appointment.color}}>
                                            <div className="first-row">
                                                <p>{new Date(appointment.appointmentDate.startingDate).toString().slice(16, 21)}</p>
                                                <p>{appointment.firstName} {appointment.lastName.slice(0, 1)}</p>
                                            </div>
                                            <p>{appointment.description}</p>
                                        </div>
                                        )}
                                    </div>
                                </div>
                                {index <= 5 && <hr />}
                                </>
                                )}
                        </div>
                </div>


                <div className="print-sec-col">
                    <h2>Vos collaborateurs:</h2>
                    <div className="print-collaborators-container">
                        
                        {collaboratorsList.map((collaborator, index) =>
                            <div className="print-collaborator-container">
                                <PersonIcon color={collaborator.color} width={"2rem"} height={"2rem"}/>
                                <p>{collaborator.firstName} {collaborator.lastName}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PrintCard
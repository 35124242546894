import React, {useReducer, useContext  } from 'react'
import DataContext from '../../../../context/DataContext'
import axiosApi from '../../../../services/axios.config'
import "./CreateAppointment.css"

function CreateAppointment(props) {
    const {collaboratorsList} = useContext(DataContext);
    const {activeUser} = useContext(DataContext)
    const {appointmentsList} = useContext(DataContext);
    const {context_dispatch} = useContext(DataContext)

    const reducer = (state, action) => {
        switch(action.type) {
            case "setFirstName": 
                return {...state, firstName: action.payload}
            case "setLastName": 
                return {...state, lastName: action.payload}
            case "setDescription": 
                return {...state, description: action.payload}
            case "setStartingDate": 
                return {...state, startingDate: action.payload}
            case "setEndingDate": 
                return {...state, endingDate: action.payload}
            case "setCollaboratorId": 
                return {...state, collaborator_id: action.payload}
        }
    }

    const [state, dispatch] = useReducer(reducer, 
        { 
        firstName: null, 
        lastName: null, 
        description: null,
        startingDate: props.activeDate,
        endingDate: props.activeDate,
        collaborator_id: activeUser._id,
        collaborator_name: activeUser.lastName + " " + activeUser.firstName
        })

    const handleDateInput = (payload, type) => {
        if(type === "date") {
            dispatch( {type: "setStartingDate", payload: payload + state.startingDate.slice(10, 16)});
            console.log(payload);
            console.log(state.startingDate);
        } else if(type === "startingHour") {
            // console.log(state.startingDate.slice(0, 11) + payload);
            dispatch( {type: "setStartingDate", payload: state.startingDate.slice(0, 11) + payload });
        } else if(type === "endingHour") {
            // console.log(state.startingDate.slice(0, 11) + payload);
            dispatch( {type: "setEndingDate", payload: state.startingDate.slice(0, 11) + payload});

        }
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();

        axiosApi.post("/addAppointment", {
            firstName: state.firstName,
            lastName: state.lastName,
            description: state.description,
            startingDate: state.startingDate,
            endingDate: state.endingDate,
            collaborator_id: state.collaborator_id,
            collaborator_name: state.collaborator_name
        }).then(response => {
            response.data.appointment.color = activeUser.color;
            
            context_dispatch({type: "addAppointment", payload: response.data.appointment})
        })

        context_dispatch({type: "setActiveAppointment", payload: null})
        context_dispatch({type: "setAppointmentStep", payload: null})    
    }

  return (
    <form className='modify-appointment-form' onSubmit={handleSubmitForm}>
        <div>        
            <label htmlFor="">Nom du client</label>
            <input type="text" name="" id="" value={state.lastName} onChange={(e) => dispatch({type: "setLastName", payload: e.target.value})}/>
        </div>

        <div>
            <label htmlFor="">Prénom du client</label>
            <input type="text" name="" id="" value={state.firstName} onChange={(e) => dispatch({type: "setFirstName", payload: e.target.value})}/>
        </div>

        <div>
            <label htmlFor="">Description</label>
            <input type="text" name="" id="" value={state.description} onChange={(e) => dispatch({type: "setDescription", payload: e.target.value})}/>
        </div>

        <div>
            <label htmlFor="">Date</label>
            <input type="date" name="" id="" value={state.startingDate.slice(0, 10)} onChange={(e) => handleDateInput(e.target.value, "date")}/>
        </div>

        <div>
            <label htmlFor="">Heure de départ</label>
            <input type="time" name="" id="" value={new Date(state.startingDate).toString().slice(16, 21)} onChange={(e) => handleDateInput(e.target.value, "startingHour")}/>
        </div>

        <div>
            <label htmlFor="">Heure de fin</label>
            <input type="time" name="" id="" value={new Date(state.endingDate).toString().slice(16, 21)} onChange={(e) => handleDateInput(e.target.value, "endingHour")}/>
        </div>

        <div>
            <label htmlFor="">Collaborateur</label>

            <select value={state.collaborator_id} onChange={(item) => {
                dispatch({type: "setCollaboratorId", payload: item.target.value})
                context_dispatch({type: "setActiveUser", payload: collaboratorsList.filter(collaborator => collaborator._id === item.target.value)[0]  })
            }}>

            {collaboratorsList.map((collaborator, index) =>
                <option value={collaborator._id} key={index}>{collaborator.firstName}</option>
            )}
            </select>

        </div>

        <button className='creation-confirm-button'>Confirmer</button>
    </form>
  )
}

export default CreateAppointment
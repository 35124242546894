import React, { useReducer } from 'react'
import Card from '../../components/calendar/card/card'
import { useContext } from 'react'
import DataContext from '../../context/DataContext'
import "./Day.css"

function Day() {
  const {appointmentsList} = useContext(DataContext);
  const {activeDate} = useContext(DataContext);
  const {activeDay} = useContext(DataContext);

  return (
    appointmentsList != null &&
    <div className="day-container">
      <Card 
      activePanel={"day"} 
      activeDay={activeDay} 
      activeDate={activeDate} 
      />
    </div>
  )
}

export default Day
import React, { useContext } from 'react'
import {motion} from "framer-motion"
import * as mdIcons from "react-icons/md";
import DataContext from '../../context/DataContext';
import "./Modal.css"

// export const SetModal = (type, content) => {
//     const {context_dispatch} = useContext(DataContext)

//     context_dispatch({type: "toggleModal", payload: true})
//     context_dispatch({type: "setModalType", payload: type})
//     context_dispatch({type: "setModalContent", payload: content})
// }

function Modal(props) {

    // const {modalContent} = useContext(DataContext)
    // const {modalType} = useContext(DataContext)
    const {context_dispatch} = useContext(DataContext)

    const handleCloseModal = () => {
        props.dispatch({type: "toggleModal", payload: false})
        // context_dispatch({type: "clearModalContent"})
        // context_dispatch({type: "clearModalType"})
    }
  return (
    <React.Fragment>
        <div className="black-filter"></div>
        <motion.div 
        className="modal-container"
        initial={{x: "100%"}}
        animate={{x: 0}}
        exit={{x: "100%"}}
        >
            <mdIcons.MdClose size={"1.5rem"} onClick={() => handleCloseModal()}/>
            { props.modalType === "success" && <p className='modal-success'>Succès: {props.modalContent}</p>}
            { props.modalType === "error" && <p className='modal-error'>Erreur: {props.modalContent}</p>}
        </motion.div>
    </React.Fragment>
  )
}

export default Modal
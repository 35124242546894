import React, {useReducer, useContext, useRef, useEffect} from 'react'
import DataContext from '../../../../context/DataContext'
import axiosApi from '../../../../services/axios.config'
import { RiDeleteBinLine } from "react-icons/ri";
import "./ModifyAppointment.css"
import DeleteButton from '../../../DeleteButton/DeleteButton';

function ModifyAppointment(props) {
    const {collaboratorsList} = useContext(DataContext);
    const {appointmentsList} = useContext(DataContext)
    const {activeUser} = useContext(DataContext)
    const {fetchMonth} = useContext(DataContext)
    const {context_state, context_dispatch} = useContext(DataContext)
    const {searchResults} = useContext(DataContext)

    // console.log(props.appointment_id);

    const reducer = (state, action) => {
        switch(action.type) {
            case "setFirstName": 
                return {...state, firstName: action.payload}
            case "setLastName": 
                return {...state, lastName: action.payload}
            case "setDescription": 
                return {...state, description: action.payload}
            case "setStartingDate": 
                return {...state, startingDate: action.payload}
            case "setEndingDate": 
                return {...state, endingDate: action.payload}
            case "setCollaboratorId": 
                return {...state, collaborator_id: action.payload}
            case "setCollaboratorName": 
                return {...state, collaborator_name: action.payload}
            case "setUnknownCollaborator": 
                return {...state, unknownCollaborator: action.payload}
        }
    }

    const [state, dispatch] = useReducer(reducer, 
        { 
        firstName: props.firstName, 
        lastName: props.lastName, 
        description: props.description,
        startingDate: props.startingDate,
        endingDate: props.endingDate,
        collaborator_id: props.collaborator_id,
        collaborator_name: props.collaborator_name,
        iterator: props.iterator,
        unknownCollaborator: true
        })

    const handleDateInput = (payload, type) => {
        if(type === "date") {
            dispatch( {type: "setStartingDate", payload: payload + state.startingDate.slice(10, 16)});
        } else if(type === "startingHour") {
            dispatch( {type: "setStartingDate", payload: state.startingDate.slice(0, 11) + payload });
        } else if(type === "endingHour") {
            dispatch( {type: "setEndingDate", payload: state.startingDate.slice(0, 11) + payload});

        }
    }
    // console.log(state.iterator);


    const handleSubmitForm = (e) => {
        e.preventDefault();

        if(props.searchPage == true) {
            const newArray = [...searchResults];

            newArray.splice(props.index, 1,{
                appointmentDate: { startingDate: state.startingDate, endingDate: state.endingDate },
                firstName: state.firstName,
                lastName: state.lastName,
                description: state.description,
                startingDate: state.startingDate,
                endingDate: state.endingDate,
                collaborator_id: state.collaborator_id,
                collaborator_name: state.collaborator_name
                })
            
            context_dispatch({type: "setSearchResults", payload: newArray})
        }

        axiosApi.put("/updateAppointment", {
            firstName: state.firstName,
            lastName: state.lastName,
            description: state.description,
            startingDate: state.startingDate,
            endingDate: state.endingDate,
            collaborator_id: state.collaborator_id,
            appointment_id: props.appointment_id
        }).then(response => {
        
        fetchMonth()

        context_dispatch({type: "setActiveAppointment", payload: null})
        context_dispatch({type: "setAppointmentStep", payload: null})    
        });

    }

    const handleDeleteAppointment = (e) => {
        // e.preventDefault();

        if(props.searchPage == true) {
            // setSearchResults context dispatch 
            // console.log(searchResults[props.index]);
            const newArray = [...searchResults];

            newArray.splice(props.index, 1)
            
            context_dispatch({type: "setSearchResults", payload: newArray})
            // console.log(context_state.searchResults)
        }

        axiosApi.delete("/deleteAppointment", { data: { appointment_id: props.appointment_id }})
        .then(response => {
            fetchMonth()
        });

        context_dispatch({type: "setActiveAppointment", payload: null})
        context_dispatch({type: "setAppointmentStep", payload: null})    
    }
    const alreadyRunned = useRef(false)

    useEffect(() => {
      if(alreadyRunned.current === false) {
        alreadyRunned.current = true;

        collaboratorsList.forEach(collaborator => {
            if(collaborator._id === state.collaborator_id) {
             dispatch({type: "setUnknownCollaborator", payload: false})
            }
        });
      }
    })
    
  return (
    <form className='modify-appointment-form' onSubmit={handleSubmitForm}>
        <div>        
            <label htmlFor="">Nom du client</label>
            <input type="text" name="" id="" value={state.lastName} onChange={(e) => dispatch({type: "setLastName", payload: e.target.value})}/>
        </div>

        <div>
            <label htmlFor="">Prénom du client</label>
            <input type="text" name="" id="" value={state.firstName} onChange={(e) => dispatch({type: "setFirstName", payload: e.target.value})}/>
        </div>

        <div>
            <label htmlFor="">Description</label>
            <input type="text" name="" id="" value={state.description} onChange={(e) => dispatch({type: "setDescription", payload: e.target.value})}/>
        </div>

        <div>
            <label htmlFor="">Date</label>
            <input type="date" name="" id="" value={state.startingDate.slice(0, 10)} onChange={(e) => handleDateInput(e.target.value, "date")}/>
        </div>

        <div>
            <label htmlFor="">Heure de départ</label>
            <input type="time" name="" id="" value={new Date(state.startingDate).toString().slice(16, 21)} onChange={(e) => handleDateInput(e.target.value, "startingHour")}/>
        </div>

        <div>
            <label htmlFor="">Heure de fin</label>
            <input type="time" name="" id="" value={new Date(state.endingDate).toString().slice(16, 21)} onChange={(e) => handleDateInput(e.target.value, "endingHour")}/>
        </div>

        <div>
            <label htmlFor="">Collaborateur</label>

            {
                state.unknownCollaborator === false ? (
            <select value={state.collaborator_id} onChange={(item) => {
                const foundCollaborator = collaboratorsList.filter(collaborator => collaborator._id === item.target.value)[0];
                dispatch({type: "setCollaboratorId", payload: item.target.value})
                dispatch({type: "setCollaboratorName", payload: foundCollaborator.lastName + " " + foundCollaborator.firstName})
                context_dispatch({type: "setActiveUser", payload:  foundCollaborator })
            }}>

            {collaboratorsList.map((collaborator, index) =>
                <option value={collaborator._id} key={index}>{collaborator.firstName}</option>
            )}
            
            </select>
                )
                :
                (
                    // <p>{state.collaborator_name}</p>
                    <select value={state.collaborator_id} onChange={(item) => {
                        const foundCollaborator = collaboratorsList.filter(collaborator => collaborator._id === item.target.value)[0];
                        dispatch({type: "setCollaboratorId", payload: item.target.value})
                        dispatch({type: "setCollaboratorName", payload: foundCollaborator.lastName + " " + foundCollaborator.firstName})
                        context_dispatch({type: "setActiveUser", payload:  foundCollaborator})
                    }}>
                    {collaboratorsList.map((collaborator, index) =>
                        <option value={collaborator._id} key={index}>{collaborator.firstName}</option>
                    )}
                    </select>
                )
            }
        </div>
        
        <div className="bottom-buttons">
            <button className='confirm-button'>Confirmer</button>
            <DeleteButton handleDeleteAppointment={handleDeleteAppointment}/>
        </div>
    </form>
  )
}

export default ModifyAppointment
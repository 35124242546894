import React, { useContext, useEffect } from 'react'
import "./Login.css"
import axiosApi from '../../services/axios.config'
import { useReducer } from 'react';
import logo from "../../assets/img/svg/Logo-O&P-CMJN.svg"
import { FaEye } from "react-icons/fa";
import jwt_decode from 'jwt-decode';
import {useNavigate, redirect} from "react-router-dom"
import DataContext from '../../context/DataContext';

function Login() {

  const {context_dispatch} = useContext(DataContext)
  const {isLoggedIn} = useContext(DataContext)

  const navigate = useNavigate()
    const reducer = (state, action) => {
        switch (action.type) {
            case "loginNameInput":
                return {...state, loginName: action.payload}
            case "passwInput":
                return {...state, passw: action.payload}
            case "handleConnMessage":
              return {...state, connMessage: action.payload}
            case "showPassword":
              return {...state, showPassword: action.payload}
            default:
                break;
        }
    }
    const [state, dispatch] = useReducer(reducer, {loginName: "", passw: "", connMessage: "", showPassword: false})

    const handleSubmitForm = (e) => {
        e.preventDefault();
        async function login() {
            try {
              const response = await axiosApi.post("/login", {loginName: state.loginName, pass: state.passw});
              // console.log(response.data);
              dispatch({type: "handleConnMessage", payload: response.data.message});
              const decoded = jwt_decode(response.data.token);
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("pharmacy_id", decoded.pharmacy_id);
              navigate("/Months")
            } catch (error) {
              dispatch({type: "handleConnMessage", payload: "Connexion échoué, mauvais nom d'utilisateur ou mot de passe"});
            }
          }
        login()
    }


  return (
    <div className="login-container">
      <img src={logo} alt="Logo agenda optic&price" />
      <p>{state.connMessage}</p>
        <form onSubmit={handleSubmitForm}>
          <div className="input-container">
            <label htmlFor="">Identifiant</label>
            <input type="text" value={state.loginName} placeholder="Insérez votre identifiant" onChange={(e) => dispatch({type: "loginNameInput", payload: e.target.value})} /> 
          </div>

          <div className="input-container">
            <label htmlFor="">Mot de passe</label>

            <div className="login-password-container">
              <input type={state.showPassword ? "text" : "password"} value={state.passw} placeholder="Insérez votre mot de passe" onChange={(e) => dispatch({type: "passwInput", payload: e.target.value})}/>
              <FaEye size={"1.5rem"} className={state.showPassword ? "password-eye active" : "password-eye"} onClick={() => dispatch({type: "showPassword", payload: !state.showPassword})}/>
            </div>
          </div>

            <button>Connectez-vous</button>
        </form>
    </div>
  )
}

export default Login
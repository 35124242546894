import React, { useContext, useEffect, useRef, useReducer } from 'react'
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {motion} from "framer-motion"
import * as goIcons from "react-icons/go";
import * as rIcons from "react-icons/ri";
import * as aiIcons from "react-icons/ai";
import DataContext from '../../context/DataContext';
import axiosApi from '../../services/axios.config';
import "./HeaderSecondSection.css"

function HeaderSecondSection() {
  const reducer = (state, action) => {
    switch (action.type) {
        case "toggleSearchbar":
            return {...state, searchbarActive: !state.searchbarActive, printPageActive: false}
        case "togglePrintPage":
          return {...state, printPageActive: !state.printPageActive, searchbarActive: false}
        case "handleSearchInput":
          return {...state, searchValue: action.payload}
        case "handleYearSelection":
          return {...state, activeYear: action.payload}
        case "clearInputField":
          return {...state, searchValue: ""}
        default:
            break;
    }
}
    const yearCarouselRef = useRef();
    const activeYearRef = useRef();

    const {fetchMonth} = useContext(DataContext)
    const {pharmacyInfos} = useContext(DataContext)
    const {context_dispatch} = useContext(DataContext)
    const {selectedMonth} = useContext(DataContext)
    const {selectedYear} = useContext(DataContext)
    const {activeMonth} = useContext(DataContext)
    const {getMonthStr} = useContext(DataContext)
    const {month} = useContext(DataContext)


    const today = new Date();
  
    let allYear = [];
    const alreadyRunned = useRef(false);
    const actualYear = today.getFullYear();

    if(pharmacyInfos != null){
      allYear = pharmacyInfos.years_list.flat();
  
      if(allYear[allYear.length-1] != actualYear) {
        if(alreadyRunned.current == false) {
          alreadyRunned.current = true;
          
          axiosApi.put("/addYear", {
            newYear: actualYear
          })
        }
        allYear.push(actualYear);
      } 
    }

    const [activeMonthDropdown, setActiveMonthDropdown] = useState(false)

    const location = useLocation();
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, { searchbarActive: false, printPageActive: false, searchValue: "", activeYear: actualYear})
    
    const handleSearch = async () => {
      try {
        const response = await axiosApi.get("/searchAppointments", {
          headers: {
            'value': state.searchValue
          }
        });
        context_dispatch({type: "setSearchResults", payload: response.data.appointments})
      } catch (error) {
        console.error(error);
      }
    }

    function handleSearchResult() {
      handleSearch();
      dispatch({type: "clearInputField"});
      dispatch({type: "toggleSearchbar"});
    }

    function handleClickedYear(year, index) {
      dispatch({ type: "handleYearSelection", payload: year});
      fetchMonth(allYear[index], null);
      context_dispatch({type: "setSelectedYear", payload: allYear[index]})
    }

    function handleDropdownActivation() {
        setActiveMonthDropdown(!activeMonthDropdown);

        if(location.pathname === "/Stats") {
          navigate("/Months");
        }
    }

    // async function handleMonth(month_index) {
    //   let result = await fetchMonth(selectedYear, month_index);
    //   // console.log(res);
    //   // console.log(month);
    //   console.log("New month fetched");

    //     context_dispatch({type: "setSelectedMonth", payload: month_index})
    //     context_dispatch({type: "setActiveMonth", payload: getMonthStr(month_index)})
    //     setActiveMonthDropdown(!activeMonthDropdown);
    // }

    async function handleMonth(month) {
      const result = await fetchMonth(selectedYear, month)

      context_dispatch({type: "setSelectedMonth", payload: month})
      context_dispatch({type: "setActiveMonth", payload: getMonthStr(month)})
      // handleSelection("month", result);
      setActiveMonthDropdown(!activeMonthDropdown);
  }
    const dynamicDropdownVariants = {
      open: {
        height: "360px",
      transition: {
        type: "spring",
        bounce: 0,
        duration: 0.7,
        delayChildren: 0.4
      }
      },
      closed: {
          height: "0px",
        transition: {
          type: "spring",
          bounce: 0,
          duration: 0.3
        }
      }
    }

    const dropdownItemsContainerVariants = {
      open: {
        opacity: 1,
        display: "flex",
        transition: {
            duration: 0.1,
            delayChildren: 0.1
        }
      },
      closed: {
          opacity: 0,
          display: "none",
          transition: {
              duration: 0.1,
          }
      }
    }

    const dropdownItemVariants = {
      open: {
        opacity: 1,
        display: "flex",
        transition: {
            duration: 0.1,
        }
      },
      closed: {
          opacity: 0,
          display: "none",
          transition: {
              duration: 0.1,
          }
      }
    }

  return (
    <div className="header-second-section-container" style={{ justifyContent: state.searchbarActive === false ? "space-between" : "flex-end"}}>
      { state.searchbarActive === false && state.printPageActive === false  && 
      <React.Fragment>
        <div
        animate={{}}
        className="months-dropdown-container">
          <p onClick={() => handleDropdownActivation()}>{activeMonth}</p>
        </div>
        
        <motion.div 
        className="dynamic-dropdown-part"
        animate={activeMonthDropdown ? "open" : "closed"}
        variants={dynamicDropdownVariants}
        layout
        > 
          <motion.div 
          className="all-months"
          variants={dropdownItemsContainerVariants}
          >
          {activeMonthDropdown && 
            <React.Fragment>
            <motion.hr variants={dropdownItemVariants}/>
            <motion.div variants={dropdownItemVariants} className="year-selector-carousel-container" ref={yearCarouselRef}>
              <motion.div variants={dropdownItemVariants}>
              {allYear.map((year, index) => (
              <p className={year === state.activeYear? "year active" : "year"} onClick={()=> handleClickedYear(year, index)}>{year}</p>
              ))}
              </motion.div>
            </motion.div>
            <motion.hr variants={dropdownItemVariants}/>
            <motion.div variants={dropdownItemVariants} className="months-container">
              <p className={activeMonth == "Janvier" ? "active" : null} onClick={() => handleMonth(1)}>Janvier</p>
              <p className={activeMonth == "Février" ? "active" : null} onClick={() => handleMonth(2)}>Février</p>
              <p className={activeMonth == "Mars" ? "active" : null} onClick={() => handleMonth(3)}>Mars</p>
              <p className={activeMonth == "Avril" ? "active" : null} onClick={() => handleMonth(4)}>Avril</p>
              <p className={activeMonth == "Mai" ? "active" : null} onClick={() => handleMonth(5)}>Mai</p>
              <p className={activeMonth == "Juin" ? "active" : null} onClick={() => handleMonth(6)}>Juin</p>
              <p className={activeMonth == "Juillet" ? "active" : null} onClick={() => handleMonth(7)}>Juillet</p>
              <p className={activeMonth == "Août" ? "active" : null} onClick={() => handleMonth(8)}>Août</p>
              <p className={activeMonth == "Septembre" ? "active" : null} onClick={() => handleMonth(9)}>Septembre</p>
              <p className={activeMonth == "Octobre" ? "active" : null} onClick={() => handleMonth(10)}>Octobre</p>
              <p className={activeMonth == "Novembre" ? "active" : null} onClick={() => handleMonth(11)}>Novembre</p>
              <p className={activeMonth == "Décembre" ? "active" : null} onClick={() => handleMonth(12)}>Décembre</p>
            </motion.div>
            <motion.hr />
            </React.Fragment>
          }
          </motion.div>
        </motion.div>  
          
      </React.Fragment>
    } {state.searchbarActive === false && state.printPageActive === false && 
        <div className="year-selection-container">
          <p>{state.activeYear}</p>
        </div>
        }
        <div className="print-and-search-container">
          <div className="search-container menu-icons" style={{flexBasis: state.searchbarActive ? "100%" : "auto"}}>
              {state.searchbarActive === true && 
                <input type="search" value={state.searchValue} onChange={(e) => dispatch({type: "handleSearchInput", payload: e.target.value})}/>
              }
              { state.searchbarActive === false && 
              <div className="searchIcon-container"><goIcons.GoSearch size="20px" style={{marginRight: state.searchbarActive === false ? "0" : "1rem"}} onClick={() => dispatch({type: "toggleSearchbar"})}/></div>}
              { state.searchbarActive === true && <Link to="/Search"><aiIcons.AiOutlineCheckCircle size="30px" onClick={() => handleSearchResult()}/></Link>}
          </div>
          <div className="print-container menu-icons" style={{flexBasis: state.printPageActive === false ? "auto" : "100%", borderRadius: state.printPageActive === false ? "50%" : "1rem", justifyContent: state.printPageActive === false ? "center" : "flex-end"}}>
              <Link to="/Print" ><rIcons.RiPrinterLine size="20px" style={{marginRight: state.printPageActive === false ? "0" : "1rem"}} onClick={() => dispatch({type: "togglePrintPage"})}/></Link>
          </div>
        </div>
    </div>
  )
  
}

export default HeaderSecondSection
import React, { useContext, useReducer, useRef, useEffect, useState } from 'react'
import "./Collaborators.css"
import axiosApi from '../../services/axios.config';
import DataContext from '../../context/DataContext';
import CollaboratorCard from './Collaborator.card';
import { RiDeleteBinLine } from "react-icons/ri";
import { IoAddCircle } from "react-icons/io5";
import PersonIcon from './PersonIcon';
import DeleteButton from '../../components/DeleteButton/DeleteButton';

function Collaborators() {
    const reducer = (state, action) => {
        switch (action.type) {
            case "setActiveStep":
                return {...state, activePageStep: action.payload }
            case "setActiveCollaboratorArrayPositionIndex":
                return {...state, activeCollaboratorArrayPositionIndex: action.payload}
            case "setDeleteConfirm":
                return {...state, deleteConfirm: action.payload}

            case "setCollaboratorFirstName":
                return {...state, collaboratorFirstName: action.payload}
            case "setCollaboratorLastName":
                return {...state, collaboratorLastName: action.payload}
            case "setJobChoice":
                return {...state, jobChoice: action.payload }
            case "clearInputs":
                return {...state, collaboratorFirstName: null, collaboratorLastName: null, jobChoice: "Titulaire"}
            default:
                break;
        }
    }
    const [state, dispatch] = useReducer(reducer, { activePageStep: 0, jobChoice: "Titulaire", activeCollaboratorArrayPositionIndex: 0, collaboratorFirstName: "", collaboratorLastName: "", deleteConfirm: false})


    let {collaboratorsList} = useContext(DataContext);
    const {fetchMonth} = useContext(DataContext)
    const {colorsList} = useContext(DataContext)
    const {activeColor} = useContext(DataContext)
    const {activeColorIndex} = useContext(DataContext)
    const {activeColorIndexbuffer} = useContext(DataContext)
    const {context_dispatch} = useContext(DataContext);
    const {deleteButtonArmed} = useContext(DataContext)
    const {activeUser} = useContext(DataContext)

    const alreadyRunned = useRef(false)


    useEffect(() => {
      if(alreadyRunned.current === false) {
        alreadyRunned.current = true;

        if(collaboratorsList.length < 1) {
            dispatch({type: "setActiveStep", payload: 1})
        }

        for(let i = 0; i < colorsList.length; i++) {
            if(colorsList[i].inUse === false) {
                context_dispatch({type: "setActiveColor", payload: colorsList[i]})
                context_dispatch({type: "setActiveColorIndex", payload: i})
                break;
            }
        }
      }
    
    }, [])

    useEffect(() => {
        // console.log(activeColorIndex);
    }, [activeColorIndex])

    const handleColorSelection = (color, index) => {
        context_dispatch({type: "setActiveColor", payload: color})
        context_dispatch({type: "setActiveColorIndex", payload: index})
        // const getLatest = async () => {
        //     const latestValue = await context_dispatch({type: "setActiveColorIndex", payload: index})
        // }
        // console.log(activeColorIndex);
    }

    const handleAddCollaboratorButton = () => {
        for(let i = 0; i < colorsList.length; i++) {
            if(colorsList[i].inUse === false) {
                context_dispatch({type: "setActiveColor", payload: colorsList[i]})
                context_dispatch({type: "setActiveColorIndex", payload: i})
                break;
            }
        }
        dispatch({type: "clearInputs"})
        dispatch({type: "setActiveStep", payload: 1})
    }

    const handleCollaboratorSubmit = (e) => {
        e.preventDefault();

        axiosApi.post("/addCollaborator", {
            firstName: state.collaboratorFirstName,
            lastName: state.collaboratorLastName,
            color: activeColor.hex,
            color_id: activeColorIndex,
            job: state.jobChoice
        }).then(response => {
            // response.data.collaborator.color_id = activeColorIndex;
            // console.log(response.data.collaborator.color_id);
            context_dispatch({type: "addCollaborator", payload: response.data.collaborator})
            context_dispatch({type: "setActiveUser", payload: response.data.collaborator})
            dispatch({type: "setActiveStep", payload: 0})

            const newColorState = {hex: activeColor.hex, inUse: true}
            const updateColorsList = [...colorsList]

            updateColorsList.splice(activeColorIndex, 1, newColorState)

            context_dispatch({type: "setColorsList", payload: updateColorsList})

        })
    }

    const handleUpdateCollaborator = (e) => {
        e.preventDefault();

        // console.log(activeColorIndex);

        axiosApi.put("/updateCollaborator", {
            collaborator_id: activeUser._id,
            firstName: state.collaboratorFirstName,
            lastName: state.collaboratorLastName,
            color: activeColor.hex,
            color_id: activeColorIndex,
            job: state.jobChoice
        }).then(response => {

            collaboratorsList.splice(state.activeCollaboratorArrayPositionIndex, 1, response.data.collaborator)
            let updateColorsList = [...colorsList]

            updateColorsList[activeColorIndexbuffer].inUse = false; // previous active color deactivation
            updateColorsList[response.data.collaborator.color_id].inUse = true; // new active color
            // console.log(response.data.collaborator.color_id);
            // console.log(updateColorsList);

            context_dispatch({type: "setColorsList", payload: updateColorsList})
            dispatch({type: "setActiveStep", payload: 0})
            fetchMonth();
        })
    }   

    const handleDeleteCollaborator = (e) => {
        axiosApi.delete("/deleteCollaborator", { data: { collaborator_id: activeUser._id }})
        .then(response => {
            console.log(colorsList);
            colorsList[activeColorIndex].inUse = false;
            collaboratorsList.splice(state.activeCollaboratorArrayPositionIndex, 1)
            context_dispatch({type: "setDeleteButtonArmed", payload: false})

            // console.log(activeColorIndex);
            if(collaboratorsList.length < 1) {
                dispatch({type: "clearInputs"})
                dispatch({type: "setActiveStep", payload: 1})
            } else {
                dispatch({type: "setActiveStep", payload: 0})
            }
        });
    }
    
  return (
    <div className="collaborators-container">
        {

        state.activePageStep === 0 &&
            <div className="collaborators-list-container">
                {collaboratorsList.map((collaborator, index) => 
                    <CollaboratorCard collaborator={collaborator} dispatch={dispatch} index={index}/>
                )}
                <div className="collaborators-add-btn-container">
                    <IoAddCircle size={"4rem"} onClick={() => handleAddCollaboratorButton()}/>
                </div>
            </div>
        }
        {

        state.activePageStep === 1 &&
            <div className="add-collaborators-container">
                    <div className="add-collaborator-header">
                        <h2>Ajouter un collaborateur</h2>
                    </div>
                    <div className="add-collaborator-form">
                        <div className="left-items">
                            <PersonIcon color={activeColor.hex} width={"7rem"} height={"7rem"} iconSize={"2rem"}/>
                        </div>
                        <div className="right-items">
                            <form onSubmit={handleCollaboratorSubmit}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <label htmlFor="">Nom</label>
                                    <input type="text" value={state.collaboratorLastName} onChange={(e) => dispatch({type: "setCollaboratorLastName", payload: e.target.value})}/>
                                </div>

                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <label htmlFor="">Prénom</label>
                                    <input type="text" value={state.collaboratorFirstName} onChange={(e) => dispatch({type: "setCollaboratorFirstName", payload: e.target.value})}/>
                                </div>

                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <label htmlFor="">Poste</label>
                                    <select name="" id="" value={state.jobChoice} onChange={(e) => dispatch({type: "setJobChoice", payload: e.target.value})}>
                                        <option value="Titulaire">Titulaire</option>
                                        <option value="Opticien">Opticien</option>
                                        <option defaultValue="true" value="Préparateur">Préparateur</option>
                                    </select>
                                </div>

                                <div className="bottom-buttons">
                                    <button className='collaborator-confirm-button'>Confirmer</button>
                                    <DeleteButton handleDeleteCollaborator={handleDeleteCollaborator} disabled={true}/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="add-collaborator-colors">
                        <div className="add-collaborator-colors-inner">
                        { colorsList.map((color, index) => 
                            <div className={"color-container"} onClick={color.inUse === false ? () => handleColorSelection(color, index) : null} key={index} style={color.inUse ? {background: "none", border: "4px solid" + color.hex} : index === activeColorIndex ? {background: "none", border: "4px solid" + color.hex} : {backgroundColor: color.hex}}></div>
                        )}
                        </div>
                    </div>
            </div>

        }
                {

state.activePageStep === 2 &&
    <div className="add-collaborators-container">
            <div className="add-collaborator-header">
                <h2>Modifier un collaborateur</h2>
            </div>
            <div className="add-collaborator-form">
                <div className="left-items">
                    <PersonIcon color={activeColor.hex} width={"7rem"} height={"7rem"} iconSize={"2rem"}/>
                </div>
                <div className="right-items">
                    <form onSubmit={handleUpdateCollaborator}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label htmlFor="">Nom</label>
                            <input type="text" value={state.collaboratorLastName} onChange={(e) => dispatch({type: "setCollaboratorLastName", payload: e.target.value})}/>
                        </div>

                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label htmlFor="">Prénom</label>
                            <input type="text" value={state.collaboratorFirstName} onChange={(e) => dispatch({type: "setCollaboratorFirstName", payload: e.target.value})}/>
                        </div>

                        <div style={{display: "flex", flexDirection: "column"}}>
                            <label htmlFor="">Poste</label>
                            <select name="" id="" value={state.jobChoice} onChange={(e) => dispatch({type: "setJobChoice", payload: e.target.value})}>
                                <option value="Titulaire">Titulaire</option>
                                <option value="Opticien">Opticien</option>
                                <option defaultValue="true" value="Préparateur">Préparateur</option>
                            </select>
                        </div>

                        <div className="bottom-buttons">
                            {deleteButtonArmed === false && <button className='collaborator-confirm-button'>Confirmer</button> }
                            <DeleteButton handleDeleteCollaborator={handleDeleteCollaborator}/>
                        </div>
                    </form>
                </div>
            </div>
            <div className="add-collaborator-colors">
                <div className="add-collaborator-colors-inner">
                { colorsList.map((color, index) => 
                    <div className={"color-container"} onClick={color.inUse === false ? () => handleColorSelection(color, index) : null} key={index} style={color.inUse ? {background: "none", border: "4px solid" + color.hex} : index === activeColorIndex ? {background: "none", border: "4px solid" + color.hex} : {backgroundColor: color.hex}}></div>
                )}
                </div>
            </div>
    </div>

}
    </div>
  )
}

export default Collaborators